import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { message  } from 'antd';
import { useState, useEffect } from 'react'
import intl from "../../locales";
import { useNavigate,useLocation ,useSearchParams } from 'react-router-dom'
export default function HotNewsDetails() {
    const locale =localStorage.getItem('lang') || 'zh'
    const [info,setInfo] = useState(0)
    const navigate = useNavigate()
    // let pagestate = useLocation()
    // const { id } =pagestate.state
    async function fetchData() {
        const res = await fetch("https://fapi.aucfan-cn.com/site/site/getNewInfo", {
            method: 'POST',
            body: JSON.stringify({
                id:id  
            }), 
            headers: { 
                'Accept-Language': locale,
                "Content-Type": 'application/json'
            }, 
        });
        const json = await res.json();
        console.log(json)
        if (json.code != 200) {
            message.error(json.message) 
        }  
        setInfo(json.data.data[0])
    }
    function goPage(){
        navigate(-1)
    }
    let  [searchParams,setobj] = useSearchParams()
    // let idquery = searchParams.get('id')
    const id =  searchParams.get('id')
    // console.log(idquery)
    useEffect(()=>{
        window.scrollTo(0, 0)
        fetchData();
    },[id])
    return (
        <>
            {/* <Head>
                <title>{intl.formatMessage({ id: '热点资讯' })} </title>
            </Head> */}
            <Header  muneActivepr={3}></Header>
            <div className='flex flex-col items-center '>
                <div className='flex mt-[120px] w-[1300px] '>
                    <div className='flex items-center  cursor-pointer' onClick={goPage}>
                            <div className="w-[52px] h-[52px] flex justify-center items-center   rounded-full  bg-[#F5F5F5] cursor-pointer" >
                                <img src='/images/setp.png' width="32" height='32' alt=""></img>
                            </div>
                        <div className="text-[#333] text-2xl font-medium   leading-normal ml-[20px]" >{intl.formatMessage({ id: '上一步' })}</div>
                    </div>
                </div>
                <div className='w-[880px] mt-[-42px]'> 
                  <div className=" text-[#333] text-4xl font-medium  w-[880px] text-center ">{intl.formatMessage({ id: '热点资讯' })}</div>
                    <div className="w-[880px] mt-[103px] text-center text-[#333] text-[32px] font-medium    leading-[48px]">{info.title}</div>
                    <div className="text-center text-[#333] text-2xl font-normal    leading-normal mt-[30px]">{info.create_time}</div>
                    <img src={info.image} width="880" height='660' alt="" className='my-[80px] w-[880px] h-[660px] object-cover rounded-lg'></img> 
                    <div className="w-[880px] text-[#333] text-[26px] font-normal    leading-[39px]"  dangerouslySetInnerHTML={{ __html: info.text }}></div>
                </div>

                <div className="w-[1300px] mt-[80px] mb-[80px] flex items-center  text-[#2557A7] text-xl font-normal  leading-[100%]">
                    <div className="text-[#333] text-xl font-normal ] ">{intl.formatMessage({ id: '首页' })}</div>
                    <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '首页' })}-{intl.formatMessage({ id: '热点资讯' })}</div>
                </div>
                <Footer />
            </div>
        </>  
    )
}