
import intl from "../../locales";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { message } from 'antd';

import {useEffect,useState} from 'react'
const locale  =localStorage.getItem('lang') || 'zh';
export default function Platform() {
    const [heImage,setHeImage] = useState([])//合作伙伴
    useEffect(() => { 
        async function fetchData() {
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getBanner", {
                method: 'POST',
                body: JSON.stringify({
                    type: 4 
                }), 
                headers: { 
                    'Accept-Language': locale, 
                    "Content-Type":'application/json'
                },
            }); 
            const json = await res.json();  
            console.log(json)
            if (json.code != 200) {
                message.error(json.message)
            }
            setHeImage(json.data)
        }
        fetchData();
          window.scrollTo(0, 0)
    }, []); 
    return (  
        <> 
            {/* <Head>
                <title>{ intl.formatMessage({ id: "平台介绍" })}</title>
            </Head> */} 
            <Header muneActivepr={1} muneActivetwopr={0} showTowMenupr={false}></Header>
             
            <div className='flex flex-col items-center '>  
                <img src={locale=='zh'? ('/images/aboutzh.jpg') :( locale=='jp'?'/images/aboutjp.jpg' : '/images/abouten.jpg') } width="1920" height='520' alt=""></img>
                <div className='h-[570px] py-[80px] box-border flex justify-center  w-[100%] ' style={{ backgroundImage: 'url(/images/about4.png)' }}>
                    <img src='/images/about1.jpg' width="610" height='410' alt=""></img> 
                    <div className='ml-[70px]'>  
                        <div className='flex items-center'>
                            <img src='/images/icon_huoyuan2.png' width="46" height='46' alt=""></img>
                            <div className='text-[#2557A7] text-[36px] ml-[20px]'>{ intl.formatMessage({ id: "货源优势" }) } </div>
                        </div>
                        <div className="text-[#2557A7]  text-[24px] font-medium   leading-[100%] mt-[63px]">{ intl.formatMessage({ id: "· 庞大的全品类供应链体系" })}</div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]">{ intl.formatMessage({ id: "拥有近7000+日本本土供应商，近300万货品SKU与诸多日本知名品牌保持长期、稳定的合作关系" })}</div>
                        <div className="text-[#2557A7]  text-[24px] font-medium    mt-[39px] leading-[100%]">{ intl.formatMessage({ id: "· 品质好货 物美价优" })} </div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "供应商均为日本本土源头厂广商，与本地商品采用的原料及生产工艺保持一致，在保障商品质量的同时，拥有更大的价格优势" }) }}></div>
                    </div>
                </div>
                <div className=' py-[80px] box-border flex justify-center '>
                    <div className='mr-[70px]'>
                        <div className='flex items-center'>
                            <img src='/images/icon_wuliu.png' width="46" height='46' alt=""></img>
                            <div className='text-[#2557A7] text-[36px] ml-[20px]'>{ intl.formatMessage({ id: "物流优势" })} </div>
                        </div>
                        <div className="text-[#2557A7]  text-[24px] font-medium   leading-[100%] mt-[63px]">{ intl.formatMessage({ id: "· 与大型物流公司达成战略合作" })} </div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]">{ intl.formatMessage({ id: "为货物量身定制合适的运输方案，可提供国内多港口的海运整箱、拼箱服务及国际空运服务" })}</div>
                        <div className="text-[#2557A7]  text-[24px] font-medium    mt-[39px] leading-[100%]">{ intl.formatMessage({ id: "· 多种业务场景的一站式服务" })}  </div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "提供中日跨境物流服务及配套的物流规划与咨询、海关申报及仓储等服务，针对普货及冷链提供适配合规标准安全稳定的清关及运输服务" }) }}></div>
                    </div>
                    <img src='/images/about3.jpg' width="610" height='410' alt=""></img>
                </div>
                <div className='h-[570px] py-[80px] box-border flex justify-center  w-[100%] ' style={{ backgroundImage: 'url(/images/about5.png)' }}>
                    <img src='/images/about2.jpg' width="610" height='410' alt=""></img>
                    <div className='ml-[70px]' >
                        <div className='flex items-center'>
                            <img src='/images/icon_cangchu.png' width="46" height='46' alt=""></img>
                            <div className='text-[#2557A7] text-[36px] ml-[20px]'>{ intl.formatMessage({ id: "仓储优势" })}  </div>
                        </div>
                        <div className="text-[#2557A7]  text-[24px] font-medium   leading-[100%] mt-[60px]">{ intl.formatMessage({ id: "· 提供专业的仓储管理" })}  </div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]" > 
                                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "商家发货至奈特喜日本平台仓，即可享受代收、存储、管理、分拣、打包、贴单、发货等跨境仓储服务" }) }}>

                                </div>
                        </div>
                        <div className="text-[#2557A7]   text-[24px] font-medium    mt-[39px] leading-[100%]"> { intl.formatMessage({ id: "· 直击跨境物流痛点  帮助商家实现降本增效" })}</div>
                        <div className="w-[620px] text-neutral-400 text-[20px] font-normal   leading-[30px] mt-[24px]">{ intl.formatMessage({ id: "多销售渠道仓库共享，解决商家货物周转效率低，备货成本高，库存效率低等问题" })} </div>
                    </div>
                </div>
                <div className='py-[80px]  box-border w-[1300px] flex items-center flex-col leading-[100%]'>
                    {
                       heImage.length>0?  <div className="text-[#2557A7]  text-[40px] font-medium   leading-10 mb-[80px]">{ intl.formatMessage({ id: "合作伙伴/平台" })} </div> :''
                    }

                    <div className=' flex  flex-wrap w-[1300px]' >
                        {  
                            heImage.map((element, index) => {
                                return (
                                    <>
                                        <img src={element.image || ''} width="292" alt='' height='120' key={index} className={`mb-[26px] h-[120px] w-[292px] object-cover ${(index+1)%4!=0  ?'mr-[44px]':''}`} ></img>
                                    </>
                                )
                            }) 
                        }
                    </div> 
                    <div className="w-[100%]  flex items-center text-[#2557A7]  text-[16px] font-normal   leading-[100%]">
                        <div className="text-[#333]  font-normal   ">{ intl.formatMessage({ id: "首页" })}  </div>
                        <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                        <div className="">{ intl.formatMessage({ id: "关于我们" })} - { intl.formatMessage({ id: "平台介绍" })}  </div>
                    </div>  
                </div>
                <Footer />
            </div>
           
        </>
    )
}