


import Footer from '../components/Footer';
import Header from '../components/Header';
function App() {
  return (
    <div className="">
      <Footer />
      <Header muneActivepr={0} />
    </div>
  );
}

export default App;