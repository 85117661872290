// export default function Header (){}
// import Image from "next/image"
// import Link from "next/link"
import { useState, useEffect } from 'react'
// import { useRouter } from "next/router";
import { message ,Modal} from "antd";
import intl from "../locales";
import { useNavigate } from 'react-router-dom'

const locale = localStorage.getItem('lang') || 'zh'
export default function Header({ muneActivepr, muneActivetwopr, showTowMenupr }) {
    const options = { passive: true };
    const [bg, setBg] = useState('rgba(255,255,255,0)')
    // const { locale } = ;
    const navigate = useNavigate()
    const [showLocal, setLocale] = useState(false) //是否显示语言
    const [zoom, setZoom] = useState(1)
    const [showview, setshowview] = useState(false) //是否是移动端
    useEffect(() => {
        let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
        // let a = windowWidth /1920 
        if (windowWidth < 1000) {
            setZoom(0.9)
            setshowview(true)
        } else {
            setshowview(false)
        }

        const scroll = (event) => {
            const { scrollY } = window;
            // if (scrollY > 100) {
            //     setBg('#fff')
            // } else if(scrollY<100 && scrollY>100) {
            //     setBg('rgba(255,255,255,0)')
            // }
            if (scrollY < 200 && scrollY > 80) {
                setBg('rgba(255,255,255,0.5)')
            } else if (scrollY > 200) {
                setBg('#fff')
            } else {
                setBg('rgba(255,255,255,0)')
            }
        };
        document.addEventListener("scroll", scroll, options);
        // () => document.removeEventListener("scroll", scroll, options);
    }, [muneActivepr])
    function setLocalCl(lang) {
        localStorage.setItem('lang', lang)
        window.location.reload()
        setLocale(false)
    }
    const [showModal, setShowModal] = useState(false)
    const kefu = () => {
        // message.warning('开发中')
        setShowModal(true)
    }
    return (
        <div className="flex justify-center  items-center" style={{ zoom: zoom }}>
            <Modal open={showModal} footer={null} onCancel={() => setShowModal(false)}>
                <img src="/images/code.jpg" alt="" />
            </Modal>
            <div className={`flex justify-between items-center z-[999] px-[180px] box-border w-[1920px]  top-0   ${showview ? 'absolute left-50%' : 'fixed translate-x-[-50%] left-[50%]'}   h-[88px]`} style={{ background: bg }}>
                <div className="flex items-center">
                    {/* <img src='/images/aucfan_LOGO.png' alt="logo" className="mr-[93px] w-[120px] h-[52px]" onClick={() => navigate('/home')} ></img> */}
                    {/* <div className="flex1 flex justify-center"> */}
                    <ListItems muneActivepr={muneActivepr} muneActivetwopr={muneActivetwopr} showTowMenupr={showTowMenupr} />
                    {/* </div> */}
                </div>
                <div className="relative">
                    <div className="text-[#2557A7] text-[16px] text-center px-[18px] h-[36px] leading-[35px] rounded-[30px]  border border-blue-800 cursor-pointer" onClick={() => { setLocale(!showLocal) }}>{intl.formatMessage({ id: '选择语言' })}</div>
                    {
                        showLocal ?
                            <div className="w-[120px] h-[146px] absolute">
                                <div className="w-[120px] h-[146px] left-[-5px] top-0 absolute bg-white rounded-md shadow ">
                                    <div className={`left-[30px] cursor-pointer top-[20px] absolute ${locale == 'zh' ? 'text-blue-800' : 'text-[#333]'} text-base font-normal  `} onClick={() => setLocalCl('zh')}>
                                        Chinese
                                    </div>
                                    <div className={`left-[33px] cursor-pointer top-[62px] absolute ${locale == 'en' ? 'text-blue-800' : 'text-[#333]'} text-base font-normal  `} onClick={() => setLocalCl('en')}>
                                        English
                                    </div>
                                    <div className={`left-[25px] cursor-pointer top-[104px] absolute ${locale == 'jp' ? 'text-blue-800' : 'text-[#333]'} text-base font-normal  `} onClick={() => setLocalCl('jp')}>
                                        Japanese
                                    </div>
                                </div>
                            </div> : ''
                    }
                </div>
            </div>
            <div onClick={kefu} className="fixed flex flex-col  items-center  rounded-md py-[18px]  z-20 w-[84px] box-border bg-white top-[783px] right-[30px]" style={{ boxShadow: '0px 2px 20px 0px #3586F840' }}>
                <img src='/images/icon_lianxi.png' width="32" height='32' alt=""></img>
                <div className={`text-center text-[#2557A7] ${locale == 'zh' ? 'text-[16px]' : 'text-[14px]'} font-normal   leading-[100%] mt-[9px] `} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '客服' }) }}></div>
            </div>
        </div>
    )
}



function ListItems({ muneActivepr, muneActivetwopr, showTowMenupr }) {
    // const { locale } = useRouter();
    const [muneActive, setMuneActive] = useState(0) //一级菜单
    const [muneActivetwo, setMuneActivetwo] = useState(null) //二级菜单
    const [showTowMenu, setShowTowMenu] = useState(true)
    const navigate = useNavigate()
    const [zoom, setZoom] = useState(1)
    useEffect(() => {
        let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
        let a = windowWidth / 1920
        if (windowWidth < 1000) {
            setZoom(0.9)
        }
        setMuneActive(muneActivepr)
        setMuneActivetwo(muneActivetwopr)
        setShowTowMenu(showTowMenupr)
    }, [muneActivepr])
    const menuList = [
        { name: '首页', path: '/' },
        { name: '关于我们', path: '' },
        { name: '入驻通道', path: '' },
        { name: '热点资讯', path: '/hotNews/hotNewsHome' },
        { name: '政策规则', path: '/policyRules/rulesLIst' },
        { name: '奈特喜商城', path: '' },
    ]
    function menuClick(index) {
        if (index == 5) {
            window.open('https://www.netsea-cn.com')
        } else {
            if (index != 1 && index != 2) {
                setMuneActive(index)
                setShowTowMenu(true)
                if (index == 0 || index == 3 || index == 4) {
                    // menuList[index].path
                    navigate(menuList[index].path)
                }
            }




        }
    }
    function goPage(url) {
        navigate(url)
    }
    function menuMouseEnter(index) {
        // setMuneActive(index)
        setShowTowMenu(true)
    }
    return (
        menuList.map((product, index) => {
            return (
                <div className="mr-[60px] relative menTwoBox " key={index} onMouseLeave={() => { setShowTowMenu(false) }}>
                    <style jsx>
                        {
                            `
                                    .menTwoBox:hover{
                                        .dis{
                                            display:block
                                        }
                                        .menuListOne{
                                            color:#2557A7
                                        }
                                    }
                                `
                        }
                    </style>
                    <span onMouseEnter={() => menuMouseEnter(index)} className={`menuListOne  text-[16px]  cursor-pointer text-[#333] ${muneActive == index ? 'font-semibold' : ''}   ${(((muneActive == 1 && index == 1) || muneActive == 2 && index == 2) && showTowMenu) ? '' : ''}`} onClick={() => menuClick(index)} >
                        {intl.formatMessage({ id: product.name })}
                    </span>
                    {
                        index == muneActive ? <img src='/images/icon_selected.png' alt='' className="mt-[6px] absolute left-[50%] translate-x-[-50%] w-[25px] h-[6px]" width='25' height='6'></img> : ''
                    }

                    {
                        index == 1 ?
                            <div style={{ 'boxShadow': '0px 2px 20px 0px #3586F840' }} className=" dis h-[104px] bg-white rounded-md shadow py-[20px] px-[20px] absolute left-[50%] translate-x-[-50%] hidden">
                                <div onClick={() => { setShowTowMenu(!showTowMenu) }} className={`text-center menboxac  text-[16px] font-normal   whitespace-nowrap cursor-pointer ${muneActivetwo == 0 && muneActive == 1 ? 'text-[#2557A7]' : 'text-[#333]'}`}>
                                    <style jsx>
                                        {
                                            `
                                            .menboxac:hover{
                                              color:#2557A7
                                            } 
                                            `
                                        }
                                    </style>
                                    <div onClick={() => goPage('/aboutMe/platform')} locale={locale}>{intl.formatMessage({ id: '平台介绍' })} </div>
                                </div>
                                <div onClick={() => { setShowTowMenu(!showTowMenu) }} className={`menboxac text-center mt-[20px]   text-[16px] font-normal   whitespace-nowrap cursor-pointer ${muneActivetwo == 1 && muneActive == 1 ? 'text-[#2557A7]' : 'text-[#333]'}`}>
                                    <style jsx>
                                        {
                                            ` 
                                            .menboxac:hover{
                                              color:#2557A7 
                                            }
                                            `
                                        }
                                    </style>
                                    <div onClick={() => goPage('/aboutMe/companyProfile')} locale={locale}>{intl.formatMessage({ id: '企业介绍' })} </div>
                                </div>
                            </div> : ''
                    }
                    {
                        index == 2 ? (
                            <div className="hidden dis  h-[104px] bg-white rounded-md shadow py-[20px] px-[20px] absolute left-[50%] translate-x-[-50%]" style={{ 'boxShadow': '0px 2px 20px 0px #3586F840' }}>
                                <div onClick={() => { setShowTowMenu(!showTowMenu) }} className={`menboxac text-center text-[#2557A7]  text-[16px] font-normal   whitespace-nowrap  cursor-pointer ${muneActivetwo == 2 && muneActive == 2 ? 'text-[#2557A7]' : 'text-[#333]'}`}>
                                    <style jsx>
                                        {
                                            ` 
                                            .menboxac:hover{
                                              color:#2557A7
                                            } 
                                            `
                                        }
                                    </style>
                                    <div onClick={() => goPage('/entryChannel/supplier')} locale={locale}>{intl.formatMessage({ id: '供应商入驻' })} </div>
                                    {/* <Link href='/entryChannel/supplier' locale={locale}>{intl.formatMessage({ id: '供应商入驻' })} </Link> */}
                                </div>
                                <div onClick={() => { setShowTowMenu(!showTowMenu) }} className={`menboxac text-center text-[#2557A7]  text-[16px] font-normal   mt-[20px] whitespace-nowrap cursor-pointer ${muneActivetwo == 3 && muneActive == 2 ? 'text-[#2557A7]' : 'text-[#333]'}`}>
                                    <style jsx>
                                        {
                                            ` 
                                            .menboxac:hover{
                                              color:#2557A7
                                            } 
                                            `
                                        }
                                    </style>
                                    {/* <a href='/entryChannel/purchaser' locale={locale}>{intl.formatMessage({ id: '采购商入驻' })} </a> */}
                                    <div onClick={() => goPage('/entryChannel/purchaser')} locale={locale}>{intl.formatMessage({ id: '采购商入驻' })} </div>
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
            )
        })
    )
}



