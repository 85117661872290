// import Image from "react"
import intl from "../locales";
const locale = localStorage.getItem('lang') || 'zh'

export default function Footer() {
    return (
        <div className="w-[1920px]">
            <div style={{ backgroundImage: 'url(/images/footer.png)' }} className='w-[100%]  h-[400px] flex  pt-[60px] flex-col items-center justify-center box-border'>
                <div className=" h-14 justify-start items-center gap-11 inline-flex">
                    <div className="justify-start items-center gap-11 flex">
                        <div className="w-[22px] h-0.5 bg-white"></div>

                        <div className="text-white text-2xl  font-medium  text-center" >
                            {locale == 'en' ?
                                <>
                                    <div>{intl.formatMessage({ id: '链接中日' })} </div>
                                    <div> {intl.formatMessage({ id: '共创共赢' })}</div>
                                </>
                                : <div className=" text-center" >{intl.formatMessage({ id: '链接中日' })} &nbsp; {intl.formatMessage({ id: '共创共赢' })}</div>
                            }
                        </div>
                    </div>
                    <div className="w-[22px] h-0.5 bg-white"></div>
                </div>
                <div className="text-white text-2xl font-normal   mt-[34px] leading-normal">{intl.formatMessage({ id: '招商热线' })}：400-9906-266</div>
            </div>
            <div className="w-[100%]  px-[310px] py-[60px]  bg-[#2557A7] flex-col justify-end box-border items-center ">
                <div className="flex w-[1300px] justify-between text-[16px] ">
                    <div className="items-center  flex">
                        <img src='/images/icon_huoyuan.png' alt="" className='object-cover cursor-pointer w-[22px] h-[22px]'></img>
                        <div className="w-[179px] text-white     leading-tight ml-[10px] ">
                            {
                                locale == 'jp' ?
                                    <>
                                        大量の仕入れ先<br />
                                        充実した品揃え
                                    </>
                                    : intl.formatMessage({ id: '海量货源 , 品类齐全' })
                            }
                        </div>
                    </div>
                    <div className=" items-center  flex ">
                        <img src='/images/icon_huowu.png' alt="" className='object-cover cursor-pointer w-[22px] h-[22px]'></img>
                        <div className="w-[179px] text-white     leading-tight ml-[10px]">
                            {
                                locale == 'jp' ?
                                    <>
                                        良質な商品を<br />
                                        低価格で提供
                                    </>
                                    : intl.formatMessage({ id: '品质好货 , 物美价优' })
                            }
                        </div>
                    </div>
                    <div className=" items-center  flex  ">
                        <img src='/images/icon_line.png' alt="" className='object-cover cursor-pointer w-[22px] h-[22px]'></img>
                        <div className="w-[179px] text-white     leading-tight ml-[10px]">
                            {
                                locale == 'jp' ?
                                    <>
                                        フルカテゴリーチェーン<br />
                                        相互活発化
                                    </>
                                    : intl.formatMessage({ id: '全品链路 , 双向赋能' })
                            }
                        </div>
                    </div>
                    <div className=" items-center  flex">
                        <img src='/images/icon_anquan.png' alt="" className='object-cover cursor-pointer w-[22px] h-[22px]'></img>
                        <div className="w-[179px] text-white     leading-tight ml-[10px]" >
                            {
                                locale == 'jp' ?
                                    <>
                                        安全で迅速<br />
                                        気軽にクロスボーダー
                                    </>
                                    : intl.formatMessage({ id: '安全快捷 , 轻松跨境' })
                            }
                        </div>
                    </div>
                </div>
                <div className="text-center mt-[46px]">
                    <div className="text-white text-opacity-60 text-base font-normal   leading-none">
                        <span>{intl.formatMessage({ id: '琼公网安备 46010502000663号' })} </span>
                        <span style={{ margin: '0 60px' }}> {intl.formatMessage({ id: '琼ICP备 2022015403号-1' })}</span>
                        <span>{intl.formatMessage({ id: '增值电信业务经营许可证：合字B2-20230048' })} </span>
                    </div>
                </div>
            </div>
        </div>
    )
}