// import Head from 'next/head';
// import Image from 'next/image';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useState } from 'react';
import Title from '../../components/Title';
// import { useRouter } from "next/router";
import intl from "../../locales";
import { useEffect } from 'react'
import { message } from 'antd';

const list = [
    { name: '企业介绍', image: '/images/icon_company.png', image1: '/images/icon_company1.png' },
    { name: '团队介绍', image: '/images/icon__team.png', image1: '/images/icon__team1.png', },
    { name: '平台链接', image: '/images/icon_line1.png', image1: '/images/icon_line11.png', },
    { name: '联系我们', image: '/images/icon_lianxi.png', image1: '/images/icon_lianxi1.png', }] //合作伙伴

export default function CompanyProfile() {
    const locale = localStorage.getItem('lang') || 'zh'
    const [activeList, changeActive] = useState(0)
    // const { locales, locale } = useRouter();
    // const a = intl.formatMessage({ id: "奥可凡首页" }) 
    const [envList, setEnvList] = useState([])
    const [activeImage, changeActiveImage] = useState(0) //环境当前图片
    useEffect(() => {

        async function fetchData() {
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getBanner", {
                method: 'POST',
                body: JSON.stringify({
                    type: 2
                }),
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'application/json'
                },
            });
            const json = await res.json();
            console.log(json)
            if (json.code != 200) {
                message.error(json.message)
            }
            setEnvList(json.data)
        }
        fetchData();
        window.scrollTo(0, 0)
    }, []);

    const openwebsite = (type) => {
        window.open(type == 1 ? 'https://www.netsea-cn.com' : 'https://www.netsea-sp-cn.com/#/login')
    }
    return (
        <>
            {/* <Head> 
                <title>{intl.formatMessage({ id: "企业介绍" })}</title>
            </Head> */}
            <Header muneActivepr={1} muneActivetwopr={1} showTowMenupr={false}></Header>
            <div className='flex flex-col items-center '>
                <img src={locale == 'zh' ? '/images/aboutzh.jpg' : (locale == 'jp' ? '/images/aboutjp.jpg' : '/images/abouten.jpg')} width="1920" height='520' alt=""></img>
                <div className='w-[1300px]'>
                    <div className='pt-[50px]'>
                        <div className='flex items-center justify-between  box-border w-[1300px] '>
                            {
                                list.map((element, index) => {
                                    return (
                                        <div onClick={() => changeActive(index)} className="w-[292px] relative h-[150px]  flex flex-col rounded-lg justify-center items-center cursor-pointer" key={index} style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26', background: activeList == index ? '#488DFD' : '#fff' }}>
                                            <img src={activeList == index ? element.image1 : element.image} width="48" alt="" height='48' className=''></img>
                                            <div className={`text-[26px] mt-[16px] leading-[100%] ${activeList == index ? 'text-[#fff]' : 'text-[#2557A7]'}`} >{intl.formatMessage({ id: element.name })}</div>
                                            <div className={`w-[40px] h-[40px] bg-[#488DFD] rounded-[10px] rotate-45 absolute bottom-[-15px] ${activeList == index ? '' : 'hidden'}`} ></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* 企业介绍 */}
                    {
                        activeList == 0 ? <div className='mt-[60px] w-[1300px] text-[#2557A7]  bg-[#F5F8FF] rounded-lg py-[54px] px-[47px] mb-[80px]'>
                            <div className=" text-[36px] font-medium   leading-[100%]">{intl.formatMessage({ id: '· aucfan企业介绍' })}  </div>
                            {
                                locale == 'jp' ?
                                    <div className="leading-[150%] text-[#2557A7CC] mt-[24px] text-[16px]" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '株式会社オークファン（Aucfan Co., Ltd.）' }) }}></div>
                                    :
                                    <div className="leading-[150%] text-[#2557A7CC] mt-[24px] text-[16px]" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '株式会社オークファン（Aucfan Co., Ltd.） 成立于2007年，并于2013年在东京证券交易所上市（股票代码3674）。aucfan集团利用交易数据和自研AI技术，将产品的市场价格可视化，通过建立优化企业库存价格和销售渠道的预测模型，为零售和分销行业开发库存清算解决方案。傲可凡（海南）网络科技有限公司「以下简称“傲可凡（海南）”」于2022年在中国海南成立，作为日本上市公司株式会社傲可凡的子公司，傲可凡（海南）借助日本母公司庞大的品牌供应链资源，推出了以跨境进口商品交易业务为核心的「奈特喜」电商平台以及相应的分销合作电商技术解决方案等，涵盖美妆护肤、食品保健品、日用品、母婴用品、家电数码等诸多高频消费品类。' }) }}></div>

                            }

                        </div> : ''
                    }
                    {/* 团队介绍 */}
                    {
                        activeList == 1 ?
                            <div>
                                <div className="w-[1300px] mt-[125.37px]   justify-center items-center inline-flex flex-col">
                                    <div className="w-[1300px] h-[520px] relative">
                                        <div className="w-[1300px] h-[400px] left-0 top-[109px] absolute bg-[#F2F9FF]"> </div>
                                        <div className="left-[609px] top-[47px] absolute text-sky-50 text-[80px] font-semibold font-['MiSans'] leading-[80px]">Ryusei Fujisaki</div>

                                        <div className='left-[737px] top-[159px] absolute '>
                                            <div className="text-[#2557A7]  text-[32px] mb-[30px] font-medium   leading-[100%]">{intl.formatMessage({ id: '藤崎 竜成' })}</div>
                                            <span className="px-[20px]   py-[10px]     bg-blue-800 text-white text-lg font-medium   leading-[18px] ">
                                                {intl.formatMessage({ id: '董事长兼CEO' })}
                                            </span>
                                            <div className={` text-[#2557A7] mt-[61px]   font-medium   leading-[25.20px] ${locale == 'en' ? 'text-[16px]' : 'text-sm'}`}>
                                                {intl.formatMessage({ id: '·2018年 株式会社萬通 CEO(现任)' })}<br />
                                                {intl.formatMessage({ id: '·2018年 北京伊藤商贸有限公司 董事长(现任)' })}<br />
                                                {intl.formatMessage({ id: '·2021年 藤崎(海南)供应链有限公司 董事(现任)' })}<br />
                                                {intl.formatMessage({ id: '·2022年 傲可凡(海南)网络科技有限公司 董事长兼总经理(现任)' })}<br />
                                            </div>
                                        </div>
                                        <img width='375' height='509' className="w-[375px] h-[509px] left-[39px] top-0 absolute" alt='' src="/images/removebg2.png" />
                                    </div>

                                    <div className="w-[1300px] h-[520px] pt-[145px] justify-center items-center ">
                                        <div className="w-[1300px] h-[516px] relative">
                                            <div className="w-[1300px] h-[400px] left-0 top-[116px] absolute bg-sky-50"></div>
                                            <div className="left-[12px] top-[56px] absolute text-sky-50 text-[80px] font-semibold font-['MiSans'] leading-[80px]">YUTAKA TOU</div>
                                            <div className='left-[140px] top-[162px] absolute '>
                                                <div className=" text-[#2557A7]  text-[32px] mb-[30px] font-medium   leading-[100%]">{intl.formatMessage({ id: '藤豊' })} </div>
                                                <>
                                                    <div className='flex'>
                                                        <div className="px-[20px] mr-[20px]  py-[10px]    bg-blue-800 text-white text-lg font-medium    ">
                                                            {intl.formatMessage({ id: '执行董事' })}
                                                        </div>
                                                        <div className="px-[20px] mr-[20px]  py-[10px]     bg-blue-800 text-white text-lg font-medium    ">
                                                            {intl.formatMessage({ id: '社长室经理兼海外事业部部长' })}
                                                        </div>
                                                    </div>
                                                    <div className='mt-[20px]'>
                                                        <span className="px-[20px] mr-[20px]  py-[10px]     bg-blue-800 text-white text-lg font-medium    ">
                                                            {intl.formatMessage({ id: '株式会社 aucfan partners 董事' })}
                                                        </span>
                                                        <span className="px-[20px]   py-[10px]      bg-blue-800 text-white text-lg font-medium    ">
                                                            {intl.formatMessage({ id: 'aucfan incubation 董事' })}
                                                        </span>
                                                    </div>
                                                    <div className={` text-[#2557A7] font-medium   leading-[25.20px] mt-[37px] ${locale == 'en' ? 'text-[16px]' : 'text-sm'}`}>
                                                        {intl.formatMessage({ id: '·2013年4月加入株式会社aucfan' })}<br />
                                                        {intl.formatMessage({ id: '·2018年2月出任执行董事，兼任销售管理室经理' })}<br />
                                                        {intl.formatMessage({ id: '·2018年10月就任组织发展室经理' })}<br />
                                                        {intl.formatMessage({ id: '·2022年4月出任经营企划室经理' })}<br />
                                                        {intl.formatMessage({ id: '·2023年4月就任傲可凡(海南)网络科技有限公司 董事' })}<br />
                                                    </div>
                                                </>

                                                {/* */}
                                            </div>

                                            {/* <div className="bg-blue-800 justify-center items-center ">
                                                <div className="text-white text-lg font-medium   leading-[18px]">{intl.formatMessage({ id: '执行董事' })}  </div>
                                            </div> */}
                                            {/* <div className="w-[274px] h-[38px] px-5 py-2.5 left-[272px] top-[224px] absolute bg-blue-800 justify-center items-center gap-2.5 inline-flex">
                                                <div className="text-white text-lg font-medium   leading-[18px]">{intl.formatMessage({ id: '社长室经理兼海外事业部部长' })} </div>
                                            </div>
                                            <div className="w-[236px] h-[38px] px-5 py-2.5 left-[455px] top-[282px] absolute bg-blue-800 justify-center items-center gap-2.5 inline-flex">
                                                <div className="text-white text-lg font-medium   leading-[18px]">  {intl.formatMessage({ id: 'aucfan incubation 董事' })}</div>
                                            </div>
                                            <div className="w-[295px] h-[38px] px-5 py-2.5 left-[140px] top-[282px] absolute bg-blue-800 justify-center items-center gap-2.5 inline-flex">
                                                <div className="text-white text-lg font-medium   leading-[18px]">{intl.formatMessage({ id: '株式会社 aucfan partners 董事' })} </div>
                                            </div> */}

                                            <img width='336' height='516' className="w-[336px] h-[516px] left-[924px] top-0 absolute" alt='' src="/images/removebg1.png" />
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                    }
                    {/* 平台连接 */}
                    {
                        activeList == 2 ?
                            <div className='flex items-center flex-col mt-[120px]'>
                                <Title title='奈特喜平台' />
                                <div className='mt-[80px] flex  justify-between w-[1300px]'>
                                    <div className="w-[628px] h-[344px] bg-white rounded-lg " style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                                        <div className='h-[250px] flex justify-center items-center' style={{ borderBottom: '1px solid #DDEAFF' }}>
                                            <img src='/images/logonai.png' width="124" alt="" height='141' className=''></img>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "奈特喜平台" }) }} className='text-[#2557A7] text-center  text-[28px] flex justify-center items-center h-[94px] cursor-pointer' onClick={() => { openwebsite(1) }}>
                                        </div>
                                    </div>
                                    <div className="w-[628px] h-[344px] bg-white rounded-lg " style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                                        <div className='h-[250px] flex justify-center items-center' style={{ borderBottom: '1px solid #DDEAFF' }}>
                                            <img src='/images/logonai1.png' width="262" alt="" height='71' className=''></img>
                                        </div>
                                        <div className='text-[#2557A7]  text-center  text-[28px] flex flex-col justify-center items-center h-[94px] cursor-pointer' onClick={() => { openwebsite(2) }}>
                                            {/* {
                                                locale=='jp' ?
                                                <div>NETSEA China <br />サプライヤー管理プラットフォーム</div>:
                                                intl.formatMessage({ id: '奈特喜商家运营平台' })
                                            } */}
                                            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "奈特喜商家运营平台" }) }}></div>
                                        </div>
                                    </div>
                                </div>
                                {/* 全球应用 */}
                                <div className='mt-[120px]  flex justify-center flex-col  items-center'>
                                    <Title title={intl.formatMessage({ id: '奈特喜全球应用' })} />
                                    <img src='/images/Frame33681.png' width="1300" alt="" height='280' className='mt-[80px]'></img>
                                </div>
                            </div> : ""
                    }
                    {/* 联系我们 */}
                    {
                        activeList == 3 ?
                            <div className='flex items-center flex-col mt-[102px]'>
                                <div className='flex  justify-between items-center w-[1300px]'>
                                    <div className="w-[628px]  h-[220px] bg-white rounded-lg flex items-center justify-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                                        <img src='/images/Frame.png' width="100" alt="" height='100' className='mr-[54px]'></img>
                                        <div className='text-[34px] '>
                                            <div className="text-[#2557A780]  text-opacity-50  font-normal  leading-[100%]">{intl.formatMessage({ id: '企业邮箱' })}   </div>
                                            <div className="text-[#2557A7]    leading-[100%] mt-[30px] cursor-pointer">info@aucfan-cn.com</div>
                                        </div>
                                    </div>
                                    <div className="w-[628px]  h-[220px] bg-white rounded-lg flex items-center justify-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                                        <img src='/images/Frame1.png' width="100" alt="" height='100' className='mr-[54px]'></img>
                                        <div className='text-[34px] '>
                                            <div className="text-[#2557A7]  text-opacity-50  font-normal  leading-[100%]">{intl.formatMessage({ id: '联系电话' })} </div>
                                            <div className="text-[#2557A7]    leading-[100%] mt-[30px] cursor-pointer">400-9906-266</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[1300px] h-[220px] mt-[40px] bg-white rounded-lg flex items-center justify-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                                    <img src='/images/Frame2.png' width="100" alt="" height='100' className='mr-[50px] ml-[70px]'></img>
                                    <div className='text-[34px] flex-1'>
                                        <div className="text-[#2557A7]  text-opacity-50  font-normal  leading-[100%]">{intl.formatMessage({ id: '公司地址' })}  </div>
                                        <div className="text-[#2557A7]  text-[26px]  leading-[100%] mt-[30px] cursor-pointer">{intl.formatMessage({ id: '中国海南省海口市美兰区国兴大道7号新海航大厦1702' })} </div>
                                    </div>
                                    <img src='/images/Group.png' width="302" alt="" height='168' className='mr-[50px] ml-[70px] mt-[52px]'></img>
                                </div>
                            </div> : ""
                    }
                </div>
                {/* 企业介绍 */}
                {
                    activeList == 0 ? <div className='flex' >
                        <div className='flex justify-end pl-[10%]   h-[592px]'>
                            <div className='box-border py-[50px] bg-[#F9F9F9] w-[1610px] pl-[45px]' >
                                <div className='flex'>
                                    <div className='flex flex-col items-center mr-[168px]'>
                                        <div className="text-[#2557A7] text-[36px] font-medium   leading-loose">{intl.formatMessage({ id: '· aucfan办公环境' })}   </div>
                                        <div className='flex items-center mt-[106px]'>
                                            <img src='/images/icon_left.png' width="58" alt="" height='58' className='' onClick={() => { changeActiveImage(0 == activeImage ? envList.length - 1 : activeImage - 1) }}></img>
                                            <img src='/images/icon_right.png' width="58" alt="" height='58' className='ml-[42px]' onClick={() => { changeActiveImage(envList.length - 1 == activeImage ? 0 : activeImage + 1) }}></img>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex items-center ' style={{ overflow: 'hidden' }}>
                                        {

                                            envList.map((element, index) => {
                                                return (
                                                    (activeImage == index || activeImage + 1 == index) ? <img src={element.image || ''} width="738" height='492' alt="" key={index} className='h-[492px]  mr-[30px] object-cover'></img> : ''
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
                <div className="w-[1300px] mt-[80px] mb-[80px] flex items-center text-[#2557A7]  text-xl font-normal   leading-[100%]">
                    <div className="text-[#333] text-xl font-normal   ">{intl.formatMessage({ id: '首页' })}</div>
                    <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '关于我们' })}-{intl.formatMessage({ id: list.length > 0 ? list[activeList].name : '' })}  </div>
                </div>
                <Footer />
            </div>

        </>
    )
} 