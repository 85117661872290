
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useState, useEffect } from 'react';
import { message } from 'antd';
import intl from "../../locales";
import { useNavigate } from 'react-router-dom'

export default function RulesLIst() {
    const navigate = useNavigate()
    const locale =localStorage.getItem('lang') || 'zh'
    const [rules, setRulesType] = useState([])
    const [activePage, setPage] = useState(1) //当前页面
    const [activeRule, setRule] = useState(0) //当前的政策
    const [total, setTotal] = useState([])
    const [list, setList] = useState([])
    const [type, setType] = useState(1) //1显示列表2显示协议详情
    const [info, setInfo] = useState('') //协议详情
    async function fetchDataList() {
        const reslist = await fetch("https://fapi.aucfan-cn.com/site/site/getPolicy", {
            method: 'POST',
            headers: {
                'Accept-Language': locale,
                "Content-Type": 'json'
            },
            body: JSON.stringify({
                type_id: rules[activeRule].id,
                page: activePage,
                limit: 8 
            })
        });
        const jsonList = await reslist.json();
        if (jsonList.code != 200) {
            message.error(jsonList.message)
        }
        setList(jsonList.data.data)
        // setTotal(jsonList.data.total)
        
        var b = []
        // console.log(jsonList.data.total,'jsonList.data.total')
        for (let i = 0 ; i<=jsonList.data.total; i++){
            console.log(i)
            b.push('1')
        } 
        setTotal(b)
    }
    useEffect(() => {
        async function fetchData() {
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getPolicyType", {
                method: 'POST',
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'json'
                }
            });
            const json = await res.json();
            if (json.code != 200) {
                message.error(json.message)
            }
            setRulesType(json.data)
        }
        fetchData();
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (rules.length > 0) {
            fetchDataList()
        }
    }, [rules, activePage, activeRule])

    function menuClick(index) {
        setRule(index)
        setPage(1)
        setType(1)
    }
    function changePage(ty) {
        let a = activePage
        if (ty == 'pre') {
            if (a != 1) { 
                setPage(--a)
            }
        } else {
            if (total.length / 8 > a) {
                setPage(++a)
            }
        }
    }
    async function getinfo(id) {
        setType(2)
        const res = await fetch("https://fapi.aucfan-cn.com/site/site/getPolicyInfo", {
            method: 'POST',
            body: JSON.stringify({ 
                id: id
            }),
            headers: {
                'Accept-Language': locale,
                "Content-Type": 'json'
            }
        });
        const json = await res.json();
        if (json.code != 200) {
            message.error(json.message)
        }
        setInfo(json.data) 

    }
    return (
        <>
            {/* <Head>
                <title>{rules.length > 0 ? rules[activeRule].title : ''}</title>
            </Head> */}
            <Header muneActivepr={4} ></Header>
            <div className='flex flex-col items-center' >
                <div className="w-[100%] h-[0px]  border-blue-200   mt-[88px] border-t"></div>
                <div className='w-[1300px] flex items-start mt-[42px]'>
                    <div className="bg-[#FBFBFB]  ">
                        {
                            rules.map((ele, index) => {
                                return (
                                    <div key={index} className={`${locale=='zh'?'px-[40px]':'px-[20px]'} whitespace-nowrap  cursor-pointer  text-[16px] font-medium  leading-[48px] h-[48px] text-center ${index == activeRule ? 'text-[#2557A7] bg-[#F2F9FF] border-l-[3px] border-[#2557A7]' : 'text-[#333]'}`} onClick={() => menuClick(index)}>{ele.title}</div>
                                )
                            })
                        }
                    </div>
                    <div className=" flex-1 ml-[50px]  lisbox">
                        {
                            type == 1 ?
                                list.map((item, index) => {
                                    return <div className={`flex justify-between lis text-[16px] h-[48px] leading-[48px] cursor-pointer`} onClick={() => getinfo(item.id)}>
                                        <style jsx>
                                            {

                                                `
                                                    .lis:hover{
                                                        color:#2557A7
                                                    }
                                                `
                                            }
                                        </style>
                                        <div className='w-[800px] overflow-hidden text-ellipsis whitespace-nowrap' >
                                            {item.title}
                                        </div>
                                        <div className='text-[14px]'>
                                            {item.create_time}
                                        </div>
                                    </div>
                                }) : <div className="flex-1 ml-[85px] ">
                                    <div className='flex  items-center '>
                                        <div className="w-[34px] h-[34px] flex justify-center items-center   rounded-full  bg-[#F5F5F5] cursor-pointer" onClick={() => setType(1)}>
                                            <img src='/images/setp.png' width="20" height='20' alt="" className='w-[20px] h-[20px] object-cover'></img>
                                        </div>
                                        <div className="text-[#333] text-[16px] font-medium   leading-normal ml-[20px]">{intl.formatMessage({ id: '上一步' })}</div>
                                    </div>
                                    <div className="text-[#2557A7] text-[24px] font-medium   leading-7 mt-[30px]">{info.title}</div>

                                    <div dangerouslySetInnerHTML={{ __html: info.text }} className="w-[1070px] text-[#333] text-[14px] font-normal   leading-[30px] mt-[44px]"></div>
                                    <div className="text-[#333] text-[14px] font-normal   leading-tight mt-[58px] w-[100%] text-right">{intl.formatMessage({ id: '发布时间' })}：{info.create_time}</div>
                                </div>
                        }
                    </div>
                    {/*   */}
                </div> 
                { 
                    type == 1 ? <div className='flex items-center mt-[80px] justify-end w-[1300px] text-[15px]'>
                        <div onClick={() => changePage('pre')} className="cursor-pointer w-[40px] h-[40px]  bg-white rounded-sm text-[#999] border border-zinc-300 justify-center items-center inline-flex mr-[40px]">
                            <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&lt;</div>
                        </div>
                        {
                            total.map((item, index) => {
                                return (
                                    (total.length / 8) >= index ? <div onClick={() => { setPage(index + 1) }} key={index} className={`w-[40px] h-[40px]  rounded-sm border  border-zinc-300 justify-center items-center inline-flex mr-[10px] cursor-pointer ${activePage == index + 1 ? 'text-[#fff] bg-[#2557A7]' : 'text-[#999] bg-white '}`}>
                                        {index + 1}
                                    </div> : ''
                                )
                            })
                        }
                        <div onClick={() => changePage('next')} className="w-[40px] h-[40px]  bg-white rounded-sm border text-[#999] border-zinc-300 justify-center items-center inline-flex ml-[30px] cursor-pointer">
                            <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                        </div>
                    </div> : ''
                }

                <div className="w-[1300px] mt-[69px] mb-[84px] flex items-center  text-[#2557A7] text-[16px] font-normal  leading-[100%]">
                    <div className="text-[#333]  font-normal ] "> {intl.formatMessage({ id: '首页' })} </div>
                    <div className="px-[17px] text-[#999] text-[20px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className=""> {intl.formatMessage({ id: '政策规则' })}-{rules.length > 0 ? rules[activeRule].title : ''}</div>
                </div> 
                <Footer />
            </div>
        </>
    )
} 