

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useState, useEffect } from 'react';
import Title from '../../components/Title';
import { Modal, message } from 'antd';
import intl from "../../locales";
import { useNavigate } from 'react-router-dom'
export default function Purchaser() {
    const [showModal, setShowModal] = useState(false)
    const [activexiyi,setactiveXiyi] = useState('')
    const locale = localStorage.getItem('lang') || 'zh'
    const ruzhuopen = () => {
        window.open('http://www.netsea-cn.com')
    }
    const navigate = useNavigate() 
    const [xieyi, setxieyi] = useState([]) //协议列表
    useEffect(() => {
        // 获取登录协议
        async function xieyi() {
            const res1 = await fetch("https://fapi.aucfan-cn.com/site/site/getPurchaser", {
                method: 'POST',
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'application/json',
                },
            });
            const json1 = await res1.json();
            if (json1.code != 200) {
                message.error(json1.message)
            }
            setxieyi(json1.data)
        }
        xieyi()
        window.scrollTo(0, 0)
    }, []);
    function lookXieyi (index){
        setShowModal(true)
        setactiveXiyi( xieyi[index].policy.text)
        navigate( '/policyRules/rulesLIstDetails',{state:{id:xieyi[index].policy.id,type:1}})
    }
    
    return (
        <>

            {/* <Head>
                <title>{intl.formatMessage({ id: '采购商入驻通道' })}</title>
            </Head> */}
            <Header muneActivepr={2} muneActivetwopr={3} showTowMenupr={false}></Header>
            <div className='flex mt-[88px] flex-col items-center ' >
                <div style={{ background: 'url(/images/entryChannelBg.png)' }} className='w-[100%] h-[648px]  bg-no-repeat flex flex-col items-center  pt-[60px] box-border'>
                    <Title title={intl.formatMessage({ id: '采购商入驻通道' })} />

                    <div className='mt-[80px] flex justify-between w-[1300px]' >
                        <div className="w-[404px] h-[340px] bg-white rounded-lg shadow flex flex-col items-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                            <div className="w-[160px] h-[160px] bg-slate-50 rounded-[50%] flex justify-center items-center mt-[32px] mb-[16px]">
                                <img src='/images/icon_ruzhu.png' width="90" height='90' alt="" ></img>
                            </div>
                            <div className="text-center text-[#2557A7] text-2xl font-medium   leading-[100%]">{intl.formatMessage({ id: '采购商入驻' })}</div>
                            <div className="w-11 h-11 p-2.5 rounded-[46px] border border-blue-800 flex justify-center items-center mt-[30px]">
                                <div className="text-[#2557A7]  text-[26px] font-normal   ">1</div>
                            </div>
                        </div>
                        <div className="w-[404px] h-[340px] bg-white rounded-lg shadow flex flex-col items-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                            <div className="w-[160px] h-[160px] bg-slate-50 rounded-[50%] flex justify-center items-center mt-[32px] mb-[16px]">
                                <img src='/images/icon_shenhe.png' width="90" height='90' alt="" ></img>
                            </div>
                            <div className="text-center text-[#2557A7] text-2xl font-medium   leading-[100%]">{intl.formatMessage({ id: '采购商品' })}</div>
                            <div className="w-11 h-11 p-2.5 rounded-[46px] border border-blue-800 flex justify-center items-center mt-[30px]">
                                <div className="text-[#2557A7]  text-[26px] font-normal   ">2</div>
                            </div>
                        </div>

                        <div className='relative'>
                            <img src='/images/bg4.png' width="404" height='306' alt="" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}></img>
                            <div className={`text-[#2557A7] text-xl font-bold ${locale=='jp'?'bottom-[130px]':'bottom-[153px]'}  leading-tight absolute  left-[50%] translate-x-[-50%] text-center whitespace-nowrap`} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "奈特喜平台官网" }) }}></div>
                            <div onClick={ruzhuopen} className=" mt-[36px] w-[404px] h-[54px]  bg-blue-800 rounded-lg flex justify-center items-center text-white text-[24px] font-medium   cursor-pointer">
                                {intl.formatMessage({ id: '立即入驻' })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[80px]'>
                {
                    xieyi.map((item,index) => {
                        return (
                            <div key={index}>
                                <div className='flex items-center w-[1300px] ' >
                                    <div className="w-[44px] h-[44px]  bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                                        <div className="text-white text-[26px] font-normal ">{index+1}</div>
                                    </div>
                                    <div className="text-center text-[#2557A7]  text-[28px] font-medium  leading-[34px] ml-[20px]">{item.title}</div>
                                </div>
                                <div className='pl-[20px] box-border w-[1300px]'>
                                    <div className='pl-[64px] box-border w-[1300px] py-[40px] ' style={{ borderLeft:index==0? '1px dotted #2557A7':'' }}>
                                        <div className="w-[1214px] box-border bg-blue-500 rounded-lg py-[40px] text-white px-[46px] flex flex-col justify-between">
                                            <div>
                                                <div  dangerouslySetInnerHTML={{ __html: item.text }} className="text-[24px] font-normal  leading-9"></div>
                                            </div>
                                            {/*  */}
                                            <div  onClick={() => lookXieyi(index)}   className='mt-[47px]'>
                                                <div className='flex w-[300px] items-center text-[20px]'>
                                                    <div className="text-white  font-normal  leading-[30px] cursor-pointer">{intl.formatMessage({ id: '了解更多' })}  </div>
                                                    <div className="  px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                </div>
                                                {
                                                    item.policy?
                                                   (
                                                    <div className='flex items-center text-white text-2xl font-normal  underline leading-9    mt-[6px] cursor-pointer'>
                                                     《<div dangerouslySetInnerHTML={{ __html: item.title }}></div>》
                                                     </div>
                                                    ):''
                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>




                {/* <div className='flex items-center w-[1300px]' >
                    <div className="w-[44px] h-[44px] bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                        <div className="text-white text-[26px] font-normal ">2</div>
                    </div>
                    <div className="text-center text-[#2557A7]  text-[28px] font-medium  leading-[34px] ml-[20px]">{intl.formatMessage({ id: '采购商品' })} </div>
                </div>

                <div className='pl-[20px] box-border w-[1300px]'>
                    <div className='pl-[64px] box-border w-[1300px] py-[40px] flex  justify-between' >
                        <div className="w-[1214px] box-border  bg-blue-500 rounded-lg py-[40px] text-white px-[46px] ">
                            <div className='text-[24px]'>
                                <div className="  font-normal leading-9">{intl.formatMessage({ id: '·注意事项' })}： </div>
                                <div className="  font-normal  leading-9">{intl.formatMessage({ id: '1、个人在进行跨境交易时需正确填写本人姓名、身份证号、收件地址、手机号码等真实有效的身份信息' })}</div>
                                <div className="  font-normal  leading-9">{intl.formatMessage({ id: '2、企业在进行跨境交易时需正确填写公司名称、公司账册号、关区名称、关区号、联系人名称、手机号码等真实有效的企业信息' })}</div>
                            </div>
                            <div className='mt-[47px]' onClick={ ()=>setShowModal(true)}>
                                <div className='flex w-[300px] items-center text-[20px]'>
                                    <div className="text-white  font-normal  leading-[30px] cursor-pointer">{intl.formatMessage({ id: '了解更多' })} </div>
                                    <div className="  px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                </div>
                                <div className=" text-white text-2xl font-normal  underline leading-9    mt-[6px] cursor-pointer">《{intl.formatMessage({ id: '采购商手册 — 入驻申请' })}》</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="w-[1300px] mt-[40px] mb-[80px] flex items-center text-[#2557A7]  text-[16px] font-normal  leading-[100%]">
                    <div className="text-[#333] font-normal ] ">{intl.formatMessage({ id: '首页' })}</div>
                    <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '关于我们' })}-{intl.formatMessage({ id: '入驻通道' })}-{intl.formatMessage({ id: '采购商入驻' })}</div>
                </div>
                <Footer />
            </div>
            <Modal open={showModal}  footer={null} onCancel={() => setShowModal(false)}>
                <div dangerouslySetInnerHTML={{ __html: activexiyi }}></div>
            </Modal>
        </>
    )
}