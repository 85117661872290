
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useState, useEffect } from 'react';
import intl from "../../locales";

import { message } from 'antd';
import { useNavigate ,useLocation} from 'react-router-dom'

export default function OfficialConsultation() {
    const navigate = useNavigate()
    let pagestate = useLocation()
    const locale =localStorage.getItem('lang') || 'zh'
    const { id, title } =pagestate.state
  
    const [zixun, setZixun] = useState([])
    const [total, setToal] = useState([])
    const [activePage, setPage] = useState(1)
    async function fetchData() { 
        const res = await fetch("https://fapi.aucfan-cn.com/site/site/getNew", {
            method: 'POST',
            body: JSON.stringify({
                page: activePage,
                limit: 10, 
                type_id: id
            }),
            headers: {
                'Accept-Language': locale,
                "Content-Type": 'application/json'
            },
        });
        const json = await res.json();
        console.log(json)
        if (json.code != 200) {
            message.error(json.message)
        }
        json.data.data.forEach(item => {
            let time = new Date(item.create_time)
            item.year = time.getFullYear()
            item.date = time.getMonth() + '-' + time.getDate()
        })
        var b = []
        for (let i = 0 ; i<=json.data.total; i++){
            console.log(i)
            b.push('1')
        }
        setToal(b)

        setZixun(json.data.data)
    }
    useEffect(() => {
        if (id) {
            fetchData()
            return
        }
        window.scrollTo(0, 0)
    }, [id,activePage])

    function changpage(ty) {
        let a = activePage
        if (ty == 'pre') {
            if (a != 1) {
                setPage(--a)
            }
        } else {
            if (total.length / 8 > a) {
                setPage(++a)
            }
        }
    }
    const gopage = (url,value)=>{
        navigate(url,value)
    }
    return (
        <>
            {/* <Head>
                <title>{title}</title>
            </Head> */}
            <Header muneActivepr={3}></Header>
            <div className='flex flex-col items-center '>
            <img src={locale == 'zh' ? '/images/hotzh.jpg' : (locale == 'jp' ? '/images/hotjp.jpg' : '/images/hoten.jpg')} width="1920" height='520' alt="" className='w-[1920px] h-[520px] object-cover'></img>
                <div className='w-[1300px] '>
                    <div className='flex justify-between items-center mt-[80px] w-[1300px]'>
                        <div className='flex items-center'>
                            <div className="w-1 h-8 bg-blue-800"></div>
                            <div className="text-[#2557A7]  text-[32px] font-medium  leading-[100%] ml-[14px]">{title}</div>
                        </div>
                    </div>
                    {
                        zixun.map((item, index) =>{
                            return (
                                <div  onClick={()=>gopage('/hotNews/hotNewsDetails?id='+item.id, {state:{ id: item.id }})} key={index}>
                                    <div className='cursor-pointer hoverdiv mt-[40px] flex justify-between  border-zinc-300 border-b items-center pb-[40px]'>
                                        <style jsx>
                                            {`
                                                .hoverdiv:hover{
                                                    .hovertext{ color:#2557A7}
                                                    .hoverBor{ 
                                                        border-color:#72A8FF;
                                                    }
                                                    .hovertime{
                                                        color:#72A8FF
                                                    }
                                                }
                                            `}
                                        </style> 
                                        <img src={item.image}  alt=""  style={{width:'212px important',height:'133px'}}></img>
                                        <div className='ml-[34px] flex-1 mr-[34px]'> 
                                            <div className='h-[133px] flex justify-between flex-col '>
                                                <div className="text-[#333] w-[833px] text-2xl font-medium  flex-1 leading-9 overflow-hidden text-ellipsis whitespace-nowrap hovertext ">
                                                    {item.title}
                                                </div> 
                                                <div className="w-[833px] text-neutral-400 text-xl font-normal   leading-[30px] text3 ">{item.intro}</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-center items-center'>
                                            <div className="text-[#333] text-[28px] font-bold font-['DINPro'] leading-7 hovertext">{item.date}</div>
                                            <div className="w-16 h-8 px-2.5 py-1.5 rounded-lg border border-[#999] justify-center  items-center gap-2.5 inline-flex mt-[20px] hoverBor " >
                                                <div className=" text-xl font-bold font-['DINPro'] text-[#999] leading-tight time hovertime">{item.year}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex items-center mt-[80px]'>
                    <div onClick={() => { changpage('pre') }} className="cursor-pointer w-12 h-12 p-2.5 bg-white rounded-sm text-[#999] border border-zinc-300 justify-center items-center inline-flex mr-[50px]">
                        <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&lt;</div>
                    </div>
                    {
                        zixun.length == 0 ? <div className={`w-[48px] h-[48px]  rounded-sm border  border-zinc-300 justify-center items-center inline-flex mr-[20px] text-[#fff] bg-[#2557A7]`}>
                            1
                        </div> : ''
                    }
                    {
                        total.map((item, index) => {
                            return (
                                (total.length / 8) >= index ? <div onClick={() => { setPage(index + 1) }} key={index} className={`w-[48px] h-[48px]  rounded-sm border  border-zinc-300 justify-center items-center inline-flex mr-[20px] cursor-pointer ${activePage == index + 1 ? 'text-[#fff] bg-[#2557A7]' : 'text-[#999] bg-white '}`}>
                                    {index + 1}
                                </div> : ''
                            )
                        })
                    }
                    <div onClick={() => { changpage('next') }} className="w-12 h-12 p-2.5 bg-white rounded-sm border text-[#999] border-zinc-300 justify-center items-center inline-flex ml-[30px] cursor-pointer">
                        <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    </div>
                </div>
                <div className="w-[1300px] mt-[80px] mb-[120px] flex items-center  text-[#2557A7] text-xl font-normal  leading-[100%]">
                    <div className="text-[#333] text-xl font-normal ] ">{intl.formatMessage({ id: '首页' })} </div>
                    <div className="px-[17px] text-[#999] text-[20px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '热点资讯' })}-{intl.formatMessage({ id: title })}</div>
                </div>
                <Footer />
            </div>
        </>
    )
}