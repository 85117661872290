import intl from "../locales";
export default function Title({title}){
    return(
        <div className="justify-start items-center  inline-flex">
            <div className="w-7 h-7 px-0.5 pt-1 pb-[3px] justify-center items-center flex">
              <div className="w-6 h-[21px] relative">
                <div className="w-[19px] h-[19px] left-0 top-0 absolute bg-blue-500 rounded-3xl"></div>
                <div className="w-2.5 h-2.5 left-[6px] top-[4px] absolute"></div>
                <div className="w-[19px] h-[19px] left-[5px] top-[2px] absolute bg-white bg-opacity-40 rounded-3xl backdrop-blur-[10px]"></div>
              </div>
            </div>
            <div className="text-[#2557A7]  text-[36px]  leading-10 whitespace-nowrap font-semibold" style={{margin:'0 50px'}}>{intl.formatMessage({ id: title })} {}</div>
            <div className="w-7 h-7 px-0.5 pt-1 pb-[3px] justify-center items-center flex">
              <div className="w-6 h-[21px] relative">
                <div className="w-[19px] h-[19px] left-0 top-0 absolute bg-blue-500 rounded-3xl"></div>
                <div className="w-2.5 h-2.5 left-[6px] top-[4px] absolute"></div>
                <div className="w-[19px] h-[19px] left-[5px] top-[2px] absolute bg-white bg-opacity-40 rounded-3xl backdrop-blur-[10px]"></div>
              </div>
            </div>
          </div> 
    )
}