
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useState, useEffect } from 'react';
import Title from '../../components/Title';
import intl from "../../locales";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom'
export default function Supplier() {
    const [info, setInfo] = useState([])
    const locale =localStorage.getItem('lang') || 'zh'

    const navigate = useNavigate() 
    useEffect(() => {
        async function fetchData() {
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getTemplate", {
                method: 'POST',
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'json'
                }
            });
            const json = await res.json();
            if (json.code != 200) {
                message.error(json.message)
            }
            setInfo(json.data)
        }
        fetchData(); 
        window.scrollTo(0, 0)
    }, [])
    const ruzhuopen = ()=>{
        window.open('http://www.netsea-sp-cn.com/#/login')
    }
    const godetails = (url,value)=>{
        // router.push({pathname:'/policyRules/rulesLIstDetails' ,query:value} )
        navigate(url,value) 
        // router.push({pathname:'/policyRules/rulesLIst' ,query:value} )
    }
    return (
        <>
            {/* <Head>
                <title> {intl.formatMessage({ id: '供应商入驻通道' })} </title>
            </Head> */}
            <Header muneActivepr={2} muneActivetwopr={2} showTowMenupr={false}></Header>
            <div className='flex  flex-col items-center  mt-[88px]' >
                <div style={{ background: 'url(/images/entryChannelBg.png)' }} className='w-[100%] mb-[80px] h-[648px]  bg-no-repeat flex flex-col items-center  pt-[60px] box-border'>
                    <Title title={intl.formatMessage({ id: '供应商入驻通道' })} />
                    <div className='mt-[80px] flex justify-between w-[1300px]' >
                        <div className="w-[292px] h-[340px] bg-white rounded-lg shadow flex flex-col items-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                            <div className="w-[160px] h-[160px] bg-slate-50 rounded-[50%] flex justify-center items-center mt-[32px] mb-[16px]">
                                <img src='/images/icon_ruzhu.png' width="90" height='90' alt="" ></img>
                            </div>
                            <div className="text-center text-[#2557A7] text-2xl font-medium   leading-[100%]">{intl.formatMessage({ id: '供应商入驻' })}</div>
                            <div className="w-11 h-11 p-2.5 rounded-[46px] border border-blue-800 flex justify-center items-center mt-[30px]">
                                <div className="text-[#2557A7]  text-[26px] font-normal   ">1</div>
                            </div>
                        </div>
                        <div className="w-[292px] h-[340px] bg-white rounded-lg shadow flex flex-col items-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                            <div className="w-[160px] h-[160px] bg-slate-50 rounded-[50%] flex justify-center items-center mt-[32px] mb-[16px]">
                            <img src='/images/icon_shenhe.png' width="90" height='90' alt="" ></img>
                            </div>
                            <div className="text-center text-[#2557A7] text-2xl font-medium   leading-[100%]">{intl.formatMessage({ id: '资格审核' })}</div>
                            <div className="w-11 h-11 p-2.5 rounded-[46px] border border-blue-800 flex justify-center items-center mt-[30px]">
                                <div className="text-[#2557A7]  text-[26px] font-normal   ">2</div>
                            </div>
                        </div>
                        <div className="w-[292px] h-[340px] bg-white rounded-lg shadow flex flex-col items-center" style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}>
                            <div className="w-[160px] h-[160px] bg-slate-50 rounded-[50%] flex justify-center items-center mt-[32px] mb-[16px]">
                            <img src='/images/icon_zhinan.png' width="90" height='90' alt="" ></img>
                            </div>
                            <div className="text-center text-[#2557A7] text-2xl font-medium   leading-[100%]">{intl.formatMessage({ id: '新手指南' })}</div>
                            <div className="w-11 h-11 p-2.5 rounded-[46px] border border-blue-800 flex justify-center items-center mt-[30px]">
                                <div className="text-[#2557A7]  text-[26px] font-normal   ">3</div>
                            </div>
                        </div>
                        <div className='relative'>
                            <img src='/images/bg.png' width="292" height='250' alt="" className='w-[292px] h-[250px] object-cover' style={{ 'boxShadow': '0px 4px 20px 0px #488DFD26' }}></img>
                            <div  className={`text-[#2557A7] text-[16px] font-bold text-center  leading-tight ${locale=='jp'?'bottom-[130px]':'bottom-[153px]'}  absolute   left-[50%] translate-x-[-50%] whitespace-nowrap`} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '奈特喜平台官网' }) }}></div>
                            <div onClick={ruzhuopen} className="mt-[36px] cursor-pointer w-[292px] h-[54px]  bg-blue-800 rounded-lg flex justify-center items-center text-white text-[24px] font-medium  ">
                                {intl.formatMessage({ id: '立即入驻' })}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    info.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className='flex items-center w-[1300px] ' >
                                    <div className="w-11 h-11 p-2.5 bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                                        <div className="text-white text-[26px] font-normal  ">{index + 1}</div>
                                    </div>
                                    <div className="text-center text-[#2557A7]  text-[34px] font-medium   leading-[34px] ml-[20px]">{item.title}</div>
                                </div>
                                <div className='pl-[20px] box-border w-[1300px]'>
                                    <div className='pl-[64px] box-border w-[1300px] py-[40px] flex flex-wrap pb-[40px]' style={{ borderLeft: index == info.length - 1 ? '' : '1px dotted #2557A7' }}>
                                        {
                                            item.join.map((el, elindex) => {
                                                return (
                                                    <div key={elindex} className={`w-[360px] ${elindex == 0 ? 'text-[#fff]' : 'text-[#488DFD]'} mb-[40px] min-h-[360px] pb-[30px] box-border  ${(elindex + 1) % 3 == 0 ? '' : 'mr-[67px]'} bg-white rounded-lg border border-[#488DFD] flex flex-col items-center`} style={{ background: elindex == 0 ? 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)' : '' }}>
                                                        <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>{elindex + 1} </div>
                                                        <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                                            <div className="text-center  text-[26px] font-medium   leading-[100%] ">{el.title}</div>  
                                                            <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                                            <div className='flex-1'>
                                                                <div className='h-[289px] flex flex-col justify-between mt-[40px] px-[30px] box-border'>
                                                                    <div>
                                                                        <div className="w-[300px]  text-lg font-normal  " dangerouslySetInnerHTML={{ __html: el.text }}></div>
                                                                        {/* <div className="w-[300px]  text-lg font-normal    mt-[19px] ">·入驻主题：海外注册的实体公司</div> */}
                                                                    </div>
                                                                    {
                                                                        el.agreement ?
                                                                            // <Link href={{pathname:'/policyRules/rulesLIstDetails',query:{id:el.agreement.id,type:1}}}>
                                                                                <div onClick={()=>godetails('/policyRules/rulesLIstDetails',{state:{id:el.agreement.id,type:1}})}>
                                                                                    <div className='flex w-[300px] items-center'>
                                                                                        <div className=" text-lg font-normal   cursor-pointer">{intl.formatMessage({ id: '了解更多' })}  </div>
                                                                                        <div className="  px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                                                    </div>
                                                                                    <div className=" text-lg font-normal   underline  mt-[20px] cursor-pointer">{el.agreement ? el.agreement.title : ''}</div>
                                                                                </div>
                                                                            // </Link> 
                                                                            : ""

                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            index == 2 ?
                                                <div className="w-[360px] mb-[40px] min-h-[473px] bg-white rounded-lg border border-[#488DFD] flex  items-center flex-col justify-center">
                                                    <img src='/images/icon_more1.png' width="200" height='200' alt="" ></img>
                                                    <div className="text-blue-500 text-[26px] font-medium text-center   leading-[39px] mt-[60px]">{intl.formatMessage({ id: '更多功能即将上线' })} </div>
                                                </div> : ''
                                        }
                                        {/* <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>2 </div>
                                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">准备资质材料</div>
                                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                                <div className='flex-1'>
                                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                                        <div className="w-[300px] text-blue-500 text-lg font-normal    ">·准备注意点：注意品牌授权需提供授权公司负责人签字</div>
                                                        <div>
                                                            <div className='flex w-[300px] items-center'>
                                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                            </div>
                                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《奈特喜开店资质》</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>3 </div>
                                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">提交入驻申请</div>
                                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                                <div className='flex-1'>
                                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                                        <div>
                                                            <div className="w-[300px] text-blue-500 text-lg font-normal    ">·申请步骤</div>
                                                            <div className="w-[300px] text-blue-500 text-lg font-normal    mt-[20px]">1：填写商家信息；<br />2：上传企业誉本；<br />3：查看阅读协议，下载必签协议完成签署盖章后上传</div>
                                                        </div>

                                                        <div>
                                                            <div className='flex w-[300px] items-center'>
                                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                            </div>
                                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 入驻申请》</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div className='flex items-center w-[1300px] mt-[80px]' >
                    <div className="w-11 h-11 p-2.5 bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                        <div className="text-white text-[26px] font-normal  ">1</div>
                    </div>
                    <div className="text-center text-[#2557A7]  text-[34px] font-medium   leading-[34px] ml-[40px]">供应商入驻</div>
                </div>
                <div className='pl-[20px] box-border w-[1300px]'>
                    <div className='pl-[64px] box-border w-[1300px] py-[40px] flex  justify-between' style={{ borderLeft: '1px dotted #2557A7' }}>
                        <div className="w-[360px] text-[#fff] min-h-[473px]  bg-white rounded-lg border border-blue-500 flex flex-col items-center" style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)' }}>
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>1 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center  text-[26px] font-medium   leading-[100%] ">了解开店规则</div>
                                <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px]  text-lg font-normal    ">·品牌要求：海外注册的品牌</div>
                                            <div className="w-[300px]  text-lg font-normal    mt-[19px] ">·入驻主题：海外注册的实体公司</div>
                                        </div>

                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className=" text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className="  px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className=" text-lg font-normal   underline  mt-[20px] cursor-pointer">《招商标准及入驻规范》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>2 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">准备资质材料</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div className="w-[300px] text-blue-500 text-lg font-normal    ">·准备注意点：注意品牌授权需提供授权公司负责人签字</div>
                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《奈特喜开店资质》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>3 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">提交入驻申请</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px] text-blue-500 text-lg font-normal    ">·申请步骤</div>
                                            <div className="w-[300px] text-blue-500 text-lg font-normal    mt-[20px]">1：填写商家信息；<br />2：上传企业誉本；<br />3：查看阅读协议，下载必签协议完成签署盖章后上传</div>
                                        </div>

                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 入驻申请》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='flex items-center w-[1300px]' >
                    <div className="w-11 h-11 p-2.5 bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                        <div className="text-white text-[26px] font-normal  ">2</div>
                    </div>
                    <div className="text-center text-[#2557A7]  text-[34px] font-medium   leading-[34px] ml-[40px]">资格审核</div>
                </div>
                <div className='pl-[20px] box-border w-[1300px]'>
                    <div className='pl-[64px] box-border w-[1300px] py-[40px] flex  justify-between' style={{ borderLeft: '1px dotted #2557A7' }}>
                        <div className="w-[360px] text-[#fff] h-[360px] bg-white rounded-lg border border-blue-500 flex flex-col items-center" style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)' }}>
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>1 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center  text-[26px] font-medium   leading-[100%] ">资格初审</div>
                                <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px]  text-lg font-normal    ">·审核周期：5个工作日</div>
                                            <div className="w-[300px]  text-lg font-normal    mt-[19px] ">·注意点：请注意入驻资质材料真实有效</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] text-blue-500 h-[360px] bg-white rounded-lg border border-blue-500 flex flex-col items-center" >
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>2 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center  text-[26px] font-medium   leading-[100%] ">资格复审</div>
                                <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px]  text-lg font-normal    ">·审核周期：3个工作日</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] text-blue-500 h-[360px] bg-white rounded-lg border border-blue-500 flex flex-col items-center" >
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>3</div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center  text-[26px] font-medium   leading-[100%] ">审核进度通知</div>
                                <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px]  text-lg font-normal    ">·进度信息：入驻过程中重要信息，平台将以邮件/电话/短信等形式通知商家</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className='flex items-center w-[1300px] ' >
                    <div className="w-11 h-11 p-2.5 bg-blue-800 rounded-[46px] border border-blue-800 flex justify-center items-center ">
                        <div className="text-white text-[26px] font-normal  ">3</div>
                    </div>
                    <div className="text-center text-[#2557A7]  text-[34px] font-medium   leading-[34px] ml-[40px]">新手指南</div>
                </div>
                <div className='pl-[20px] box-border w-[1300px]'>
                    <div className='pl-[64px] box-border w-[1300px] py-[40px] flex  justify-between flex-wrap' >
                        <div className="w-[360px] text-[#fff] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center" style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)' }}>
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>1 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center  text-[26px] font-medium   leading-[100%] ">品牌授权</div>
                                <div className="w-[43px] h-0.5 bg-[#fff] mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px]  text-lg font-normal    ">·需准备材料:品牌商标资质、商标注册号/申请号、品牌logo</div>
                                            <div className="w-[300px]  text-lg font-normal     mt-[19px]">·注意点:需下载授权模板，完成签署盖章后上传</div>
                                        </div>
                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className=" text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className="  px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className=" text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 品牌授权》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>2</div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">新建商品</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div className="w-[300px] text-blue-500 text-lg font-normal    ">·注意点：请注意填写的商品信息准确、真实，因自身信息填写错误导致的损失，由商家自行负责。</div>
                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 充值、服务账单扣款》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>3 </div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">商品海关备案</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px] text-blue-500 text-lg font-normal    ">·注意点:请注意填写的商品海关备案信息真实有效</div>
                                        </div>

                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 海关备案》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center mt-[40px]">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>4</div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">仓储物流</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px] text-blue-500 text-lg font-normal    ">·注意点：请注意正确填写商品的物理管理属性，因自身信息填写错误导致的损失，由商家自行负责</div>
                                        </div>

                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 仓储物流》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex flex-col items-center mt-[40px]">
                            <div className='w-[180px] h-[39px]  flex justify-center items-center text-white text-[26px] font-medium mt-[-2px]' style={{ background: 'linear-gradient(154.82deg, #529AFD 0%, #3B89F8 55.21%, #287BF4 100%)', border: '1px solid #fff', borderTop: 'none', borderRadius: '0 0 40px 40px' }}>5</div>
                            <div className='flex flex-col items-center leading-[100%] mt-[31px]'>
                                <div className="text-center text-blue-500 text-[26px] font-medium   leading-[100%] ">充值及服务单扣款</div>
                                <div className="w-[43px] h-0.5 bg-blue-500 mt-[16px]"></div>
                                <div className='flex-1'>
                                    <div className='h-[289px] flex flex-col justify-between mt-[40px]'>
                                        <div>
                                            <div className="w-[300px] text-blue-500 text-lg font-normal    ">·注意点：请注意，需充值足够支付服务单的金额</div>
                                        </div>

                                        <div>
                                            <div className='flex w-[300px] items-center'>
                                                <div className="text-blue-500 text-lg font-normal   cursor-pointer">了解更多 </div>
                                                <div className=" text-[#488DFD] px-[10px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                            </div>
                                            <div className="text-blue-500 text-lg font-normal   underline  mt-[20px] cursor-pointer">《商家手册 — 充值、服务账单扣款》</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[360px] min-h-[473px] bg-white rounded-lg border border-blue-500 flex  items-center flex-col justify-center mt-[40px]">
                            <Image src='/images/icon_more1.png' width="200" height='200' alt="" ></Image>
                            <div className="text-blue-500 text-[26px] font-medium   leading-[39px] mt-[60px]">更多功能即将上线</div>
                        </div>
                    </div>
                </div> */}
                <div className="w-[1300px] mb-[80px] flex items-center text-[#2557A7]  text-[16px] font-normal   leading-[100%]">
                    <div className="text-[#333]  font-normal   ">{intl.formatMessage({ id: '首页' })} </div>
                    <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '入驻通道' })} - {intl.formatMessage({ id: '供应商入驻' })}</div>
                </div>
                <Footer />
            </div> 
        </>
    )
}