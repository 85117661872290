// import Head from 'next/head';
import Header from '../components/Header';
import Title from '../components/Title';
import { useState, useRef } from 'react';
import Footer from '../components/Footer';
import { Carousel, message } from 'antd';
// import { useRouter } from "next/router";
import { useEffect } from 'react'
// import Link from 'next/link'; 
import { pdfjs, Document, Page } from 'react-pdf'
import { Form, Checkbox, Modal } from "antd";
import intl from "../locales";  
import { useNavigate } from 'react-router-dom'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function Home() {

  const locale = localStorage.getItem('lang') || 'zh'
  const navigate = useNavigate()
  function onDocumentLoadSuccess({ numPages }) { 
    setNumPages(numPages);
  }
  const [isModalVisible, setisModalVisible] = useState(false)
  const [numPages, setNumPages] = useState(null);

  const [flag, setFlag] = useState(0)  //0是供应商 1是采购商

  const [swiperList, setSwiperList] = useState([])
  const [adType, setAdType] = useState(1) //1是商家账号  2是商城账号
  const [adFlag, setAdFlag] = useState(false) //是否记住密码
  const [adAgreen, setAdAgreen] = useState(false) //是否同意协议
  const [phoneValue, setPhoneValue] = useState('') //手机号
  const [passValue, setPassValue] = useState('') //密码
  const [xieyi, setxieyi] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    setPassValue(localStorage.getItem('passwordAd') || '')
    async function fetchData() {
      const res = await fetch("https://fapi.aucfan-cn.com/site/site/getBanner", {
        method: 'POST',
        body: JSON.stringify({
          type: 1
        }),
        headers: {
          'Accept-Language': locale,
          "Content-Type": 'application/json',
        },
      });
      const json = await res.json();
      if (json.code != 200) {
        message.error(json.message)
      }
      setSwiperList(json.data)
      console.log(json.data, 'fsdfsdf')
    }
    fetchData();


    // 获取登录协议
    async function xieyi() {
      const res1 = await fetch("https://fapi.aucfan-cn.com/site/site/getAgreement", {
        method: 'POST',
        headers: {
          'Accept-Language': locale,
          "Content-Type": 'application/json'
        },
      });
      const json1 = await res1.json();
      if (json1.code != 200) {
        message.error(json1.message)
      }
      setxieyi(json1.data.data)
    }


    xieyi()

  }, []);
  useEffect(() => {
    // 获取登录协议
    async function xieyi() {
      const res1 = await fetch("https://fapi.aucfan-cn.com/site/site/getAgreement", {
        method: 'POST',
        body: JSON.stringify({
          position: adType == 1 ? 2 : 1,
          type: adType == 1 ? 1 : 2,
        }),
        headers: {
          'Accept-Language': locale,
          "Content-Type": 'application/json'
        },
      });
      const json1 = await res1.json();
      if (json1.code != 200) {
        message.error(json1.message)
      }
      setxieyi(json1.data.data)
    }
    xieyi()

  }, [adType]);
  async function login() {
    // 协议
    if (!adAgreen) {
      message.error(intl.formatMessage({ id: '请先勾选同意协议' }))
      return
    }

    // 记住密码
    if (adFlag) {
      localStorage.setItem('passwordAd', passValue)
    } else {
      localStorage.removeItem('passwordAd')
    }
    // 记住密码
    //  登录
    let url = adType == 1 ? "https://fapi.aucfan-cn.com/merchant/login/login" : 'https://fapi.aucfan-cn.com/api/login/login' // 正式官网
    // let url = adType == 1 ? "https://extend.aucfan-cn.com/merchant/login/login" : 'https://fapi.aucfan-cn.com/api/login/login' //跳转测试
    let data = {}
    if (adType == 1) {
      data = {
        account: phoneValue,
        password: passValue
      } 
    } else {
      data = {
        phone: phoneValue,
        password: passValue
      }
    } 
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      // mode: 'no-cors', 
      headers: {
        'Accept-Language': locale,
        "Content-Type": 'application/json'
      },
    });
    const json = await res.json();
    if (json.code != 200) {
      message.error(json.message)
    } else {
      message.success(json.message)
      // 跳转对应网站
      window.open((adType == 1 ? 'https://www.netsea-sp-cn.com' : 'https://www.netsea-cn.com') + '?token=' + json.data.token ) // 正式官网
      // window.open((adType == 1 ? 'http://192.168.31.65:5173' : 'https://www.netsea-cn.com') + '?token=' + json.data.token ) // 跳转测试

    }
  }
  function handlePhoneChange(e) {
    setPhoneValue(e.target.value)
  }
  function handlePassChange(e) {
    setPassValue(e.target.value)
  }

  const carousel = useRef(null)
  const [activeSwiper, setAcSwiper] = useState(0)
  function afterChange(e) {
    setAcSwiper(e)
  }
  function changeSwip(e) {
    carousel.current?.prev()
    carousel.current?.goTo(e)
  }

  const [xieyiurl, setxieyiurl] = useState('https://netsea.oss-cn-hangzhou.aliyuncs.com/static/2023/09/28/6514d36427fdd/NETSEA Chinaプラットフォームサプライヤーオンライン利用規約.pdf')
  function openxieyi(file) {
    setxieyiurl(file)
    setisModalVisible(true)
  }
  function bannerLink(file) {
    if (!file) return
    if (file.includes('http')) {
      window.open(file)
    } else {
      navigate(file)
      // router.push(file)
    }
  }
  function openPage(url) {
    window.open(url)
  }


  return (
    <div>
      {/* <Head>
        <title>{intl.formatMessage({ id: '傲可凡首页' })}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head> */}
      <Header muneActivepr={0}></Header>
      {/* 轮播图 */}

      <div>
        <Modal
          v2
          open={isModalVisible}
          footer={false}
          onCancel={() => setisModalVisible(false)}
          width={1000}
          height={800}
        >
          <div>
            <Document file={xieyiurl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={930}
                />
              ))}
            </Document>
          </div>
        </Modal>
      </div>
      <div className="flex justify-center flex-col items-center">
        {/* 轮播图 */}
        <div className='relative W-[1920px] box-border'>
          <div className='relative h-[750px] bannerBox W-[1920px]'>

            {
              swiperList.length > 0 ?
                <Carousel autoplay dots={false} afterChange={afterChange} ref={carousel} style={{ 'pointer-event': 'none', height: '750px', width: '1920px' }} className='flex'>
                  {
                    swiperList.map(el => {
                      return (
                        <div className='relative w-[100vw] h-[750px] ' key={el.id}>
                          <img src={el.image || ''} width="1920" height='750' alt="" className='h-[750px] w-[1920px] object-cover' onClick={() => bannerLink(el.jump_url)}></img>
                        </div>
                      )
                    })
                  }
                </Carousel> : ''
            }
            <div className='absolute bottom-[40px] left-0 flex justify-center z-30 w-[100%]'>
              {
                swiperList.map((item, index) => {
                  return <div key={index} onClick={() => changeSwip(index)} className={` ${index == activeSwiper ? 'w-[22px] bg-blue-800 rounded-[10px]' : 'w-3 bg-black bg-opacity-20 rounded-full'} h-3  ${index == swiperList.length - 1 ? '' : 'mr-[27px]'}`} ></div>
                })
              }
            </div>
          </div>
          {
            swiperList.length > 0 ?
              <div className="w-[352px]  bg-white rounded-md shadow pt-[40px] pb-[30px] box-border px-[32px] leading-[100%] absolute top-[105px] right-[310px]" style={{ 'boxShadow': '0px 2px 20px 0px #3586F840' }}>
                <div className="text-center text-[#2557A7] text-xl font-medium   leading-tight">{intl.formatMessage({ id: '登录奈特喜' })}</div>
                <div className={`${locale == 'zh' ? 'text-[18px]' : 'text-[14px] '} mt-[26px] flex justify-center items-center`} >
                  <div onClick={() => { setAdType(1) }} className={`${adType == 1 ? 'text-[#2557A7]' : 'text-neutral-400'}  font-normal   leading-[18px] cursor-pointer w-[50%] text-center`} >
                    {
                      locale == 'jp' ? <div>サプライヤー<br />ログインID</div> : intl.formatMessage({ id: '商家账号' })
                    }
                  </div>
                  <div onClick={() => { setAdType(2) }} className={`${adType == 2 ? 'text-[#2557A7]' : 'text-neutral-400'}  font-normal   leading-[18px] cursor-pointer w-[50%] text-center`}>
                    {
                      locale == 'jp' ? <div>バイヤー<br />ログインID</div> : intl.formatMessage({ id: '商城账号' })
                    }
                  </div>
                </div>

                <div className=" rounded mt-[16px] h-[40px] overflow-hidden flex justify-center items-center px-[15px] box-border" style={{ border: '1px solid #DCDCDC' }}>
                  {/* <img src='/images/icon_yonghu.png' width="18" height='18' alt="" className='object-cover h-[18px] mr-[15px]'></img> */}
                  <input placeholder={intl.formatMessage({ id: '手机号' })} style={{ height: '48px', outline: 'none', border: 'none', width: '256px' }} value={phoneValue} onChange={handlePhoneChange}></input>
                </div>
                <div className="mt-[16px] rounded h-[40px] overflow-hidden flex justify-center items-center px-[15px] box-border" style={{ border: '1px solid #DCDCDC' }}>
                  {/* <img src='/images/icon_mima.png' width="18" height='18' alt="" className='object-cover h-[18px] mr-[15px]'></img> */}
                  <input placeholder={intl.formatMessage({ id: '密码' })} type='password' value={passValue} style={{ height: '48px', outline: 'none', width: '256px' }} onChange={handlePassChange}></input>
                </div>
                {/* <div className='px-[68px] box-border'> */}
                <div className='flex items-center mt-[16px]'>
                  <div className={`w-[14px] h-[14px] rounded-sm border flex justify-center items-center border-neutral-[#DCDCDC] text-[#fff] text-[10px] cursor-pointer ${adFlag ? 'bg-blue-800' : ''}`} onClick={() => { setAdFlag(!adFlag) }}> √ </div>
                  <div className="text-[#999] text-[14px] font-normal   leading-none ml-[6px] ">{intl.formatMessage({ id: '记住密码' })}</div>
                </div>
                <div onClick={login} className=" h-[40px]   bg-blue-800 rounded-sm justify-center items-center text-center mt-[24px] mb-[20px] text-white text-lg font-normal   leading-[40px] cursor-pointer">
                  {intl.formatMessage({ id: '登录' })}
                </div>
                <div className="text-[#2557A7] text-[16px]  font-normal   leading-none text-center cursor-pointer" onClick={() => openPage(adType==1?'https://www.netsea-sp-cn.com/#/login':'https://www.netsea-cn.com')}>{intl.formatMessage({ id: '立即入驻' })}</div>
                <div className='flex  mt-[35px]'>
                  <div className={`w-[14px] h-[14px] text-[#fff] text-[10px] flex justify-center items-center rounded-sm border border-zinc-300 cursor-pointer mt-[2px] ${adAgreen ? 'bg-blue-800' : ''}`} onClick={() => { setAdAgreen(!adAgreen) }}>√</div>
                  <div className={`w-[268px] text-xs font-normal   leading-[18px]  ml-[9px] }`}>
                    <div className="text-neutral-400  leading-[18px]" >{intl.formatMessage({ id: '点击勾选即表示同意以下协议' })}</div>
                    {
                      xieyi.map(item => {
                        return <span className="text-blue-800 leading-[18px] cursor-pointer" key={item.id} onClick={() => openxieyi(item.file)}>《 {item.title}》</span>
                      })
                    }
                  </div>
                </div>
              </div> : ''
          }

        </div>

        {/* 登录 */}
        <div className='py-[80px] w-[1300px] flex justify-center flex-col items-center'>
          <Title title={intl.formatMessage({ id: '日本品牌入华优质平台' })} />
          <div className={`w-[1300px]  text-[#8F8E91] mt-[60px] text-[20px]  ${locale == 'zh' ? 'text-center' : ''}  leading-[36px] font-normal`} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '奈特喜作为专注于为供应商及采购商双向赋能的日本品牌入华优质平台，与中国多个大型B2C电商平台实现链路打通，为供应商提供优质的仓储物流、报关清关、商品运营等服务，拓宽日本品牌商品在华销售渠道，助力日本供应商开拓中国市场；同时，奈特喜平台拥有庞大的日本全品类供应链体系，提供日本仓及中国保税仓商品一件代发服务，帮助采购商实现“低成本、低库存、低风险”式无忧分销。奈特喜平台将致力于成为跨境电商分销供应链服务一体化平台，为供应商及采购商提供平台系统一站式综合服务，为日本商家和中国消费者提供优质跨境贸易服务。' }) }}></div>
        </div>
        <div style={{ backgroundImage: 'url(/images/bg1.png)' }} className='w-[100%]  h-[745px] flex justify-center items-center'>
          <div className='w-[380px]  h-[565px] bg-[#fff] rounded-lg overflow-hidden'>
            <div className='h-[400px] flex flex-col justify-between items-center pt-[40px] pb-[30px]'>
              <img src='/images/icon_C4D1.png' width="240" height='240' alt="" className='object-cover mb-[30px] '></img>
              <div className="w-[300px] h-[60px] p-5 bg-blue-50 rounded-lg justify-center items-center gap-2.5 inline-flex  flex-col  ">
                {
                  locale == 'jp' ?
                    (
                      <>
                        <div className="text-[#2557A7]  text-xl font-medium  leading-[100%] text-center " >大量の仕入れ先</div>
                        <div className="text-[#2557A7]  text-xl font-medium  leading-[100%] text-center " >充実した品揃え</div>
                      </>
                    ) : <div className={`text-[#2557A7]  ${locale == 'en' ? 'text-[18px] leading-[150%]' : 'text-xl leading-[100%]'}  font-medium   text-center`} >{intl.formatMessage({ id: '货源海量全面' })}</div>
                }
              </div>
            </div>
            <div className='bg-[#488DFD] flex-1 w-[100%] h-[165px] flex justify-center items-center flex-col'>
              <div className={`${locale == 'en' ? 'w-[320px]' : 'w-[300px]'}  text-white text-sm font-normal leading-[150%] `} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '庞大的全品类供应链体系；拥有近7000+日本本土供应商，近300万货品SKU；与诸多日本知名品牌保持长期、稳定的合作关系' }) }}></div>
            </div>
          </div>
          <div className='w-[380px] h-[565px] bg-[#fff] rounded-lg overflow-hidden mr-[80px] ml-[80px]'>
            <div className='h-[400px] flex flex-col justify-between items-center pt-[40px] pb-[30px]'>
              <img src='/images/icon_C4D2.png' width="240" height='240' alt="" className='object-cover mb-[30px] '></img>
              <div className="w-[300px] h-[60px] p-5 bg-blue-50 rounded-lg justify-center items-center gap-2.5 inline-flex">
                <div className={`text-[#2557A7]  ${locale == 'en' ? 'text-[18px] leading-[150%]' : 'text-xl leading-[100%]'}  font-medium   text-center`}>{intl.formatMessage({ id: '品质好货 物美价优' })}</div>
              </div>
            </div>
            <div className='bg-[#488DFD] h-[165px] flex-1 w-[100%]   flex justify-center items-center'>
              <div className={`${locale == 'en' ? 'w-[320px]' : 'w-[300px]'}  text-white text-sm font-normal leading-[150%] `}>{intl.formatMessage({ id: '供应商均为日本本土源头厂商；与本地商品采用的原料及生产工艺保持一致；在保障商品质量的同时，拥有更大的价格优势' })}</div>
            </div>
          </div>
          <div className='w-[380px] h-[565px] bg-[#fff] rounded-lg overflow-hidden'>
            <div className='h-[400px] flex flex-col justify-between items-center pt-[40px] pb-[30px]'>
              <img src='/images/icon_C4D3.png' width="240" height='240' alt="" className='object-cover mb-[30px] '></img>
              <div className="w-[300px] h-[60px] p-5 bg-blue-50 rounded-lg justify-center items-center gap-2.5 inline-flex flex-col">
                {
                  locale == 'jp' ?
                    (
                      <>
                        <div className="text-[#2557A7]  text-xl font-medium  leading-[100%] text-center " >フルデジタル</div>
                        <div className="text-[#2557A7]  text-xl font-medium  leading-[100%] text-center " >対外貿易サービス</div>
                      </>
                    ) : <div className={`text-[#2557A7]  ${locale == 'en' ? 'text-[18px] leading-[150%]' : 'text-xl leading-[100%]'}  font-medium   text-center`}>{intl.formatMessage({ id: '全套数字外贸服务' })}</div>
                }
              </div>
            </div>
            <div className='bg-[#488DFD] flex-1 w-[100%] h-[165px]  flex justify-center items-center'>
              <div className={`${locale == 'en' ? 'w-[320px]' : 'w-[300px]'}  text-white text-sm font-normal leading-[150%] `} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '平台具备货源·价格·物流·仓储等优势；提供一站式跨境服务；支持小批量采购提供日本仓及保税仓货源一件代发服务' }) }}></div>
            </div>
          </div>
        </div>
        <div className='py-[84px] w-[1300px] flex justify-center flex-col items-center'>
          <Title title='aucfan' />
          {locale == 'en' || locale == 'jp' ?
            <div className={`w-[1300px]  text-[#8F8E91] mt-[60px] text-[20px] font-normal  leading-[36px]`} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '株式会社オークファン（Aucfan Co., Ltd.） 成立于2007年，并于2013年在东京证券交易所上市（股票代码3674）。aucfan集团利用交易数据和自研AI技术，将产品的市场价格可视化，通过建立优化企业库存价格和销售渠道的预测模型，为零售和分销行业开发库存清算解决方案。傲可凡（海南）网络科技有限公司「以下简称“傲可凡（海南）”」于2022年在中国海南成立，作为日本上市公司株式会社傲可凡的子公司，傲可凡（海南）借助日本母公司庞大的品牌供应链资源，推出了以跨境进口商品交易业务为核心的「奈特喜」电商平台以及相应的分销合作电商技术解决方案等，涵盖美妆护肤、食品保健品、日用品、母婴用品、家电数码等诸多高频消费品类。' }) }}></div>
            :
            <div className={`w-[1300px] text-center  text-[#8F8E91]    mt-[60px] text-[20px] font-normal  leading-[36px]`} >{intl.formatMessage({ id: '株式会社オークファン（Aucfan Co., Ltd.） 成立于2007年，并于2013年在东京证券交易所上市（股票代码3674）。aucfan集团利用交易数据和自研AI技术，将产品的市场价格可视化，通过建立优化企业库存价格和销售渠道的预测模型，为零售和分销行业开发库存清算解决方案。傲可凡（海南）网络科技有限公司「以下简称“傲可凡（海南）”」于2022年在中国海南成立，作为日本上市公司株式会社傲可凡的子公司，傲可凡（海南）借助日本母公司庞大的品牌供应链资源，推出了以跨境进口商品交易业务为核心的「奈特喜」电商平台以及相应的分销合作电商技术解决方案等，涵盖美妆护肤、食品保健品、日用品、母婴用品、家电数码等诸多高频消费品类。' })}</div>

          }

          <div className='flex justify-between w-[1300px] px-[89.5px] box-border mt-[80px]'>
            <div className="text-center">
              <div className="text-[#2557A7]  text-4xl font-bold font-['Myriad Pro'] leading-9">100{intl.formatMessage({ id: '亿' })}+</div>
              <div className="text-neutral-400 text-lg font-normal   leading-[18px] mt-[12px]">{intl.formatMessage({ id: '年均交易额（日元）' })}</div>
            </div>
            <div className="text-center">
              <div className="text-[#2557A7]  text-4xl font-bold font-['Myriad Pro'] leading-9">300{intl.formatMessage({ id: '万' })}+</div>
              <div className="text-neutral-400 text-lg font-normal   leading-[18px] mt-[12px]">{intl.formatMessage({ id: '日货SKU' })}</div>
            </div>
            <div className="text-center">
              <div className="text-[#2557A7]  text-4xl font-bold font-['Myriad Pro'] leading-9">{locale == 'jp' ? '7,000+' : '7000+'}</div>
              <div className="text-neutral-400 text-lg font-normal   leading-[18px] mt-[12px]">{intl.formatMessage({ id: '日本本土注册供应商' })}</div>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(/images/bg1.png)' }} className='w-[100%]  h-[680px] flex  pt-[60px] flex-col items-center'>
          <Title title='合作模式' />
          <div className="w-[1300px] mt-[100px] flex min-h-[348px]" >
            <div className="w-[590px]  mr-[120px] bg-white rounded-lg px-[30px] py-[30px] box-border flex justify-between items-center">
              <div className='w-[220px]  relative h-[100%] rounded-lg overflow-hidden'>
                <img src='/images/bg2.jpg' layout="fill" alt="" className='w-[220px] h-[100%]'></img>
              </div>
              <div className='ml-[37px] flex h-[100%] flex-col justify-between w-[273px]'>
                {/* <div className=" text-[#333]   text-[16px]  leading-[150%] "   dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '为商家跨境在商品采销、运输清关、专业仓储、订单发货、渠道赋能等供应链各环节量身定制使用方案，帮助商家降本、增效、拓展规模' }) }}> </div> */}
                <div className={`text-[#333]   text-[16px]  leading-[150%]`} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '为商家跨境在商品采销、运输清关、专业仓储、订单发货、渠道赋能等供应链各环节量身定制使用方案，帮助商家降本、增效、拓展规模' }) }}></div>
                <div className=" h-[60px]  mt-[20px]  bg-blue-800 rounded-lg  text-center " onClick={() => openPage(' https://www.netsea-sp-cn.com/#/login')}>
                  <div className="text-white text-xl font-medium  leading-[60px] cursor-pointer">{intl.formatMessage({ id: '供应商入驻' })}</div>
                </div>
              </div>
            </div>
            <div className="w-[590px] min-h-[348px] bg-white rounded-lg px-[30px] py-[30px] box-border flex justify-between items-center">
              <div className='w-[220px]  relative h-[100%] rounded-lg overflow-hidden'>
                <img src='/images/bg3.jpg' layout="fill" alt="" className='w-[100%] h-[100%]'></img>
              </div>
              <div className='ml-[37px] flex w-[273px]  flex-col justify-between h-[100%]'>
                <div className=" text-[#333]  text-[16px]  leading-[150%]">{intl.formatMessage({ id: '平台提供一站式跨境服务，供应链稳定，无需投入大量资金，节约备货成本，实现低成本开店' })} </div>
                <div className=" h-[60px] mt-[20px]   bg-blue-800 rounded-lg  text-center " onClick={() => openPage(' https://www.netsea-cn.com')}>
                  <div className="text-white text-xl font-medium  leading-[60px] cursor-pointer">{intl.formatMessage({ id: '采购商入驻' })} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='py-[80px] w-[1300px] flex justify-center flex-col items-center'>
          <Title title={intl.formatMessage({ id: '轻松入驻' })} />
          <div className="text-stone-500 text-[28px] font-medium  leading-7 mt-[77px]">{intl.formatMessage({ id: flag ? '采购商入驻' : '供应商入驻' })}</div>
          <div className="w-10 h-3 justify-start items-start gap-4 inline-flex mt-[26px]">
            <div className={`w-3 h-3  rounded-full cursor-pointer ${!flag ? 'bg-blue-800' : 'bg-black bg-opacity-10'}`} onClick={() => setFlag(0)}></div>
            <div className={`w-3 h-3  rounded-full cursor-pointer ${flag ? 'bg-blue-800' : 'bg-black bg-opacity-10'}`} onClick={() => setFlag(1)}></div>
          </div>
          <div className='mt-[70px] flex items-center '>
            <div className="w-6 h-12 px-[7px] cursor-pointer py-[5px] mr-[47px] bg-black bg-opacity-10 rounded-md justify-center items-center gap-2.5 inline-flex text-[#fff]" onClick={() => setFlag(flag == 1 ? 0 : 1)} style={{ fontFamily: '宋体' }}>&lt;</div>
            <div>
              <img src='/images/Frame23.png' width="240" height='240' alt="" className='object-cover'></img>
              <div className="text-center text-[#333] text-[20px] font-normal leading-tight mt-[20px] mb-[14px]">{intl.formatMessage({ id: '注册会员' })}</div>
              <div className="text-center text-neutral-400 text-base font-normal  leading-none">{intl.formatMessage({ id: '第一步：注册会员' })}</div>
            </div>
            <img src='/images/Polygon.png' width="20" height='14' alt="" className={`${!flag ? 'mx-[24px]' : 'mx-[104px]'}`}></img>
            {
              !flag ? (
                <>
                  <div>
                    <img src='/images/Frame24.png' width="240" height='240' alt="" className='object-cover'></img>
                    <div className="text-center text-[#333] text-[20px] font-normal leading-tight mt-[20px] mb-[14px]">{intl.formatMessage({ id: '提交资料' })}</div>
                    <div className="text-center text-neutral-400 text-base font-normal  leading-none">{intl.formatMessage({ id: '第二步：提交资料' })}</div>
                  </div>
                  <img src='/images/Polygon.png' width="20" height='14' alt="" className='ml-[24px] mr-[24px]'></img>
                </>) : ''
            }
            <div>
              <img src='/images/Frame25.png' width="240" height='240' alt="" className='object-cover'></img>
              <div className="text-center text-[#333] text-[20px] font-normal leading-tight mt-[20px] mb-[14px]">{intl.formatMessage({ id: '签署协议' })}</div>
              <div className="text-center text-neutral-400 text-base font-normal  leading-none">{intl.formatMessage({ id: `第${flag ? '二' : '三'}步：签署协议` })}</div>
            </div>
            <img src='/images/Polygon.png' width="20" height='14' alt="" className={`${!flag ? 'mx-[24px]' : 'mx-[104px]'}`}></img>
            <div>
              <img src='/images/Frame26.png' width="240" height='240' alt="" className='object-cover'></img>
              <div className="text-center text-[#333] text-[20px] font-normal leading-tight mt-[20px] mb-[14px]">{intl.formatMessage({ id: flag ? '入驻成功' : '店铺上线' })} </div>
              <div className="text-center text-neutral-400 text-base font-normal  leading-none">{intl.formatMessage({ id: `第${flag ? '三' : '四'}步：${flag ? '入驻成功' : '店铺上线'}` })}   </div>
            </div>
            <div className="w-6 h-12 px-[7px] ml-[47px]  cursor-pointer py-[5px] bg-black bg-opacity-10 rounded-md justify-center items-center gap-2.5 inline-flex text-[#fff]" onClick={() => setFlag(flag == 1 ? 0 : 1)} style={{ fontFamily: '宋体' }}>&gt;</div>
          </div>
          <div className='mt-[60px] flex items-center text-[14px]'>
            <span className="text-neutral-400  font-medium   leading-tight">{intl.formatMessage({ id: '还想知道更多细节？可前往' })} </span>
            <div className='flex items-center' onClick={() => { navigate(!flag?  '/entryChannel/supplier' :'/entryChannel/purchaser') }}>
              <span className="text-[#2557A7]  font-medium   leading-tight cursor-pointer  ml-[10px] mr-[14px]">{intl.formatMessage({ id: '了解更多' })}</span>
              <img src='/images/icon_more.png' width="18" height='12' alt="" className='object-cover cursor-pointer'></img>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
